import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout'
import {  post } from '../../services/ApiService';
import "./style.css";
import Loader from "../../components/Loader";
import { Toaster } from 'react-hot-toast';
import 'react-quill/dist/quill.snow.css';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import MeetingGrid from "./MeetingGrid";
import { Pagination } from "antd";
import DeleteModal from "../../components/DeleteModal";
import { message } from "antd";
import usePermissionCheck from "../../hooks/usePermissionCheck";

const StaffMeeting = () => {
    const canAdd = usePermissionCheck(['Meeting Create']);
    const canEdit = usePermissionCheck(['Meeting Edit']);
    const canDelete = usePermissionCheck(['Meeting Delete']);
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setNotFound] = useState(false); 
    const [isDelete, setIsDelete] = useState(false); 
    const [meetings, setMeetings] = useState([]); 
    const [filters, setFilters] = useState({
        limit: '20',
        search:'',
        sortOrder: 'newest'
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [delVisible, setDelVisible] = useState(false);
    const [id, setId] = useState('');

    useEffect(() => {
        fetchData()
    }, [currentPage, filters]);

    const fetchData = async () => {

        try {
            setIsLoading(true);
            const Body = {
              page: currentPage,
              ...filters,
            };
            const res = await post(`api/meetings/list?type=Staff`, Body);
            if (res.success) {
              setIsLoading(false);
              setMeetings(res.meetings);
              setTotalPages(res.totalPages);
              setNotFound(false);

            } else {
                setMeetings([]);
              setTotalPages(1);
              setIsLoading(false);
              setNotFound(true);
            }
        } catch (error) {
            setMeetings([]);
            setTotalPages(1);
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showDelModal = (id) => {
        setId(id)
        setDelVisible(true);
    };
    const closeDelModal = () => {
        setDelVisible(false);
    };

    const handleDelete = async () => {

        try {
            setIsDelete(true)
            const res = await post(`api/meetings/delete/${id}`);
            setIsDelete(false)
            if (res.success) {
                message.success('Meeting deleted Successfully');
                closeDelModal();
                fetchData();
            } else {
                message.error('Meeting to delete file')

            }
        } catch (error) {
            message.error('Failed to delete file')
        }
    };



  return (
    <>
    <Layout>
        <div>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6 mb-8 mb-sm-0">
                            <h2 className="fs-4 mb-0">
                                Staff Meetings
                            </h2>
                        </div>
                        <div className="col-sm-6 mb-8 mb-sm-0 text-end">
                        </div>

                    </div>
                    <div className="card mt-4" style={{border:'transparent',borderRadius:'15px',padding:'5px'}}>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-6">
                                    <input type="text" name="search" placeholder="search..." className="form-control" value={filters.search} onChange={handleFilterChange} />
                                </div>
                                <div className="col-6 text-end">
                                    {canAdd && 
                                        <Link to={'/meetings/add'} className="btn btn-primary mt-4 btn-sm">Create New</Link>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            {isLoading  ? (
                                <div className="text-center">
                                    <Loader />
                                </div>
                            ):(
                                meetings.length > 0 && <MeetingGrid meetings={meetings}  showDelModal={showDelModal} canEdit={canEdit} canDelete={canDelete}/>
                            )}
                            {!isLoading && isNotFound && 
                                <div className="text-center">
                                <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                                <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                </div>
                            }
                            {totalPages && totalPages > 1 && 
                                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                            }

                        </div>
                    </div>
                </div>
            </div>

        </div>
        </div>
        <DeleteModal visible={delVisible} onClose={closeDelModal} onConfirm={handleDelete} isDelete={isDelete}/>
    </Layout>
    </>
  )
}

export default StaffMeeting