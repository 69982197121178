import React from 'react';
import { Modal } from 'antd';

const OrderStatsModal = ({ allStats, isStatsOpen, statsCloseModal }) => {


  return (
    <Modal
      open={isStatsOpen}
      title="Orders stats"
      width={800}
      onOk={statsCloseModal}
    >
        <table className='table table-bordered'>
            <thead>
                <tr>
                    <th width={10}>Format</th>
                    <th width={15}>Total Orders</th>
                    <th width={15}>Created Orders</th>
                    <th width={15}>Failed Orders</th>
                    <th width={5}>Avg Weight</th>
                    <th width={40}>Error</th>
                </tr>
            </thead>
            <tbody>
                {allStats && allStats !=='' && 
                    <>
                    {Object.keys(allStats).map(format => (
                        <tr key={format}>
                            <td>{format}</td>
                            <td>{allStats[format].total}</td>
                            <td>{allStats[format].created}</td>
                            <td>{allStats[format].failed}</td>
                            <td>
                                {allStats[format].avgWeight.length > 0 
                                ? allStats[format].avgWeight.join(', ')
                                : 'Nill'}
                            </td>
                            <td>
                            {allStats[format].error !== '' ? (
                                <span>{allStats[format].error}</span>
                                ) : (
                                    <span>No errors</span>
                                )}
                            </td>
                        </tr>
                    ))}

                    </>
                }

            </tbody>
        </table>

    </Modal>
  );
};

export default OrderStatsModal;
