import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import './components.css';
import { get } from '../services/ApiService';
import { usePermissions } from '../redux/PermissionsContext';

const Sidebar = () => {
  const { rolePermissions } = usePermissions();
  const [activeItem, setActiveItem] = useState('');
  const [orderCount, setOrderCount] = useState('0');
  useEffect(() => {
    const path = window.location.pathname;

    setActiveItem(path);
  }, []);

  useEffect(() => {
    getPendingOrderCount();
  }, []);

  const getPendingOrderCount = async () => {
    try {
      const res = await get('api/orders/pending/count');
      if(res.success){
        setOrderCount(res.order);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } 
  };
  function isActiveOrder(path) {
    return path === '/orders' || 
           path === '/orders/pharma-medicine' || 
           path === '/orders/assessment-required' || 
           path.startsWith('/orders/') || 
           path.startsWith('/order/assessment/');
  }
  function isActiveProduct(path) {
    return path === '/products' || 
           path === '/variations' || 
           path.startsWith('/products/assign-question/') || 
           path.startsWith('/products/edit/');
  }
  function isActiveProductList(path) {
    return path === '/products' ||
           path.startsWith('/products/assign-question/') ||
           path.startsWith('/products/edit/');
  }

  function isActiveOrdersList(path) {
    return path === '/orders' ||
           path.startsWith('/orders/') ||
           path.startsWith('/orders/edit/');
  }

  function isActiveLogs(path) {
    return path === '/near-miss-logs' || 
           path.startsWith('/near-miss-logs/add') || 
           path.startsWith('/file-manager')  
  }

  function isActiveLogsList(path) {
    return path === '/near-miss-logs' ||
           path.startsWith('/near-miss-logs/add');
  }
const hasPermission = (requiredPermissions) =>
        requiredPermissions.every(permission => rolePermissions.includes(permission));

  return (
    <>
      <div className="db-sidebar bg-body">
        <aside className="navbar navbar-expand-xl navbar-light d-block px-0 header-sticky dashboard-nav py-0">
          <div className="sticky-area border-right">
            <div className="d-flex px-6 px-xl-10 w-100 border-bottom py-7 justify-content-between">
              <a href="/" className="navbar-brand py-4">
                <img className="light-mode-img" src="/assets/images/logo.png" width={150} height={26}  />
                {/* <img className="dark-mode-img" src="/assets/images/logo.png" width={100} height={26} /> */}
              </a>
              <div className="ml-auto d-flex align-items-center">
                <div className="d-flex align-items-center d-xl-none">
                  <div className="color-modes position-relative px-4">
                    <a className="bd-theme btn btn-link nav-link dropdown-toggle d-inline-flex align-items-center justify-content-center text-primary p-0 position-relative rounded-circle" href="#" aria-expanded="true" data-bs-toggle="dropdown" data-bs-display="static" aria-label="Toggle theme (light)">
                      <svg className="bi my-1 theme-icon-active">
                        <use href="#sun-fill" />
                      </svg>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-end fs-14px" data-bs-popper="static">
                      <li>
                        <button type="button" className="dropdown-item d-flex align-items-center active" data-bs-theme-value="light" aria-pressed="true">
                          <svg className="bi me-4 opacity-50 theme-icon">
                            <use href="#sun-fill" />
                          </svg>
                          Light
                          <svg className="bi ms-auto d-none">
                            <use href="#check2" />
                          </svg>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="dark" aria-pressed="false">
                          <svg className="bi me-4 opacity-50 theme-icon">
                            <use href="#moon-stars-fill" />
                          </svg>
                          Dark
                          <svg className="bi ms-auto d-none">
                            <use href="#check2" />
                          </svg>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="dropdown-item d-flex align-items-center" data-bs-theme-value="auto" aria-pressed="false">
                          <svg className="bi me-4 opacity-50 theme-icon">
                            <use href="#circle-half" />
                          </svg>
                          Auto
                          <svg className="bi ms-auto d-none">
                            <use href="#check2" />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown no-caret py-4 px-3 d-flex align-items-center notice me-6">
                    <a href="#" className="dropdown-toggle text-body-emphasis fs-5 fw-500 lh-1 position-relative" data-bs-toggle="dropdown">
                      <i className="far fa-bell" />
                      <span className="badge bg-primary rounded-pill position-absolute fw-bold fs-13px bottom-100 start-100 translate-middle-x">1</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                    </div>
                  </div>
                </div>
                <button className="navbar-toggler border-0 px-0" type="button" data-bs-toggle="collapse" data-bs-target="#primaryMenuSidebar" aria-controls="primaryMenuSidebar" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon" />
                </button>
              </div>
            </div>
            <div className="collapse navbar-collapse bg-body position-relative z-index-5" id="primaryMenuSidebar">
              <form className="d-block d-xl-none pt-8 px-6">
                <div className="input-group position-relative bg-body-tertiary">
                  <input type="text" className="form-control border-0 bg-transparent pl-4 shadow-none" placeholder="Search Item" />
                  <div className="input-group-append fs-14px px-6 border-start border-2x">
                    <button className="bg-transparent border-0 outline-none py-5">
                      <i className="far fa-search" />
                    </button>
                  </div>
                </div>
              </form>
              <ul className="list-group list-group-flush list-group-no-border w-100 p-6">
                <li className={`list-group-item px-0 py-0 sidebar-item mb-3 border-0 ${activeItem === '/'  ? 'active' : ''}`}>
                  <a href="/" className="text-heading text-decoration-none lh-1 sidebar-link py-5 px-6 d-flex align-items-center" title="Dashboard">
                    <span className="sidebar-item-icon w-40px d-inline-block text-muted">
                      <i className="fas fa-home-lg-alt" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Dashboard</span>
                  </a>
                </li>
                {hasPermission(['Role View']) &&
                <li className={`list-group-item px-0 py-0 sidebar-item mb-3 border-0 ${activeItem === '/roles'  ? 'active' : ''}`}>
                  <a href="/roles" className="text-heading text-decoration-none lh-1 sidebar-link py-5 px-6 d-flex align-items-center" title="Roles">
                    <span className="sidebar-item-icon w-40px d-inline-block text-muted">
                      <i className="far fa-address-card" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Role</span>
                  </a>
                </li>
                } 
                {hasPermission(['User View']) && 
                <li className={`list-group-item px-0 py-0 sidebar-item mb-3 border-0 ${activeItem === '/users' || activeItem === '/users/add' || activeItem === '/users/edit'  ? 'active' : ''}`}>
                  <a href="/users" className="text-heading text-decoration-none lh-1 sidebar-link py-5 px-6 d-flex align-items-center" title="Dashboard">
                    <span className="sidebar-item-icon w-40px d-inline-block text-muted">
                      <i className="fas fa-user" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">User</span>
                  </a>
                </li>
                }
                {hasPermission(['Product View']) && 
                <li className="list-group-item px-0 py-0 sidebar-item mb-3 has-children border-0">
                  <a href="#product" className="text-heading text-decoration-none lh-1 d-flex sidebar-link align-items-center py-5 px-6 position-relative" data-bs-toggle="collapse" aria-expanded="false" title="Products">
                    <span className="sidebar-item-icon d-inline-block w-40px text-muted">
                      <i className="fas fa-shopping-bag" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Products</span>
                    <span className="position-absolute top-50 end-5 translate-middle-y"><i className="far fa-angle-down" /></span>
                  </a>
                  <div className={`collapse menu-collapse ${isActiveProduct(activeItem) ? 'show' : ''}`} id="product">
                    <ul className="sub-menu list-unstyled">
                      <li className= {`sidebar-item ${isActiveProductList(activeItem) ? 'active' : ''}`}>
                          <a href='/products' 
                            className={`sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold`}
                            title="Product List">Product List
                          </a>
                      </li>
                      {hasPermission(['Variations']) && 
                      <li className= {`sidebar-item ${activeItem === '/variations' ? 'active' : ''}`}>
                          <a href='/variations' 
                            className={`sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold `}
                            title="Product List">Variations
                          </a>
                      </li>
                      }
                    </ul>
                  </div>
                </li>
                }
                {hasPermission(['Question View']) && 

                <li className="list-group-item px-0 py-0 sidebar-item mb-3 has-children border-0">
                  <a href="#question" className="text-heading text-decoration-none lh-1 d-flex sidebar-link align-items-center py-5 px-6 position-relative" data-bs-toggle="collapse" aria-expanded="false" title="Products">
                    <span className="sidebar-item-icon d-inline-block w-40px text-muted">
                      <i className="fas fa-comment-alt" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Questions</span>
                    <span className="position-absolute top-50 end-5 translate-middle-y"><i className="far fa-angle-down" /></span>
                  </a>
                  <div className={`collapse menu-collapse ${activeItem === '/questions' || activeItem === '/questions/add' || activeItem === '/questions/edit' ? 'show' : ''}`} id="question">
                    <ul className="sub-menu list-unstyled">
                      <li className= {`sidebar-item ${activeItem === '/questions' ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/questions`} title="Product List">Question List
                        </a>
                      </li>
                      {hasPermission(['Question Create']) &&
                      <li className= {`sidebar-item ${activeItem === '/questions/add' ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/questions/add`} title="Product List">Add Question 
                        </a>
                      </li>
                      }
                    </ul>
                  </div>
                </li>
                }
                {hasPermission(['Order Management']) && 
                <li className="list-group-item px-0 py-0 sidebar-item mb-3 has-children border-0">
                  <a href="#order" className="text-heading text-decoration-none lh-1 d-flex sidebar-link align-items-center py-5 px-6 position-relative" data-bs-toggle="collapse" aria-expanded="false" title="Order">
                    <span className="sidebar-item-icon d-inline-block w-40px text-muted">
                      <i className="fas fa-shopping-cart" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Order</span>
                    <span className="position-absolute top-50 end-5 translate-middle-y"><i className="far fa-angle-down" /></span>
                  </a>
                  <div className={`collapse menu-collapse ${isActiveOrder(activeItem) ? 'show' : ''}`} id="order">
                    <ul className="sub-menu list-unstyled">
                      {hasPermission(['All Orders']) && 
                      <li className= {`sidebar-item ${isActiveOrdersList(activeItem) ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/orders`} title="Order List 1">
                            All Orders 
                        </a>
                      </li>
                      }
                      {hasPermission(['Pending P Approval']) && 
                      <li className= {`sidebar-item ${activeItem === '/orders/pharma-medicine' ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/orders/pharma-medicine`} title="Order List 2">
                          Pending P Approval <span className='badge bg-primary'>{orderCount}</span>
                        </a>
                      </li>
                      }
                      {hasPermission(['Assessment Incomplete']) && 
                      <li className= {`sidebar-item ${activeItem === '/orders/assessment-required' ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/orders/assessment-required`} title="Order List 2">
                          Assessment Incomplete
                        </a>
                      </li>
                      }
                    </ul>
                  </div>
                </li>
                }
                {hasPermission(['Events']) &&   
                <li className={`list-group-item px-0 py-0 sidebar-item mb-3 border-0 ${activeItem === '/events'  ? 'active' : ''}`}>
                  <a href="/events" className="text-heading text-decoration-none lh-1 sidebar-link py-5 px-6 d-flex align-items-center" title="Dashboard">
                    <span className="sidebar-item-icon w-40px d-inline-block text-muted">
                      <i className="fas fa-user" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Events</span>
                  </a>
                </li>
                }
                {/* {hasPermission(['Site Setting']) && 
                <li className="list-group-item px-0 py-0 sidebar-item mb-3 has-children border-0">
                  <a href="#setting" className="text-heading text-decoration-none lh-1 d-flex sidebar-link align-items-center py-5 px-6 position-relative" data-bs-toggle="collapse" aria-expanded="false" title="Setting">
                    <span className="sidebar-item-icon d-inline-block w-40px text-muted">
                      <i className="fas fa-cog" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Setting</span>
                    <span className="position-absolute top-50 end-5 translate-middle-y"><i className="far fa-angle-down" /></span>
                  </a>
                  <div className={`collapse menu-collapse ${activeItem === '/site-setting' || activeItem === '/setting' ? 'show' : ''}`} id="setting">
                    <ul className="sub-menu list-unstyled">
                      <li className="sidebar-item">
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" href="../dashboard/profile-settings.html" title="Profile settings">Profile settings</a>
                      </li>
                      {hasPermission(['Site Setting']) &&
                      <li className= {`sidebar-item ${activeItem === '/events'? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" href="/Events" title="Site settings">Events</a>
                      </li>
                      }
                    </ul>
                  </div>
                </li>
                } */}
                {hasPermission(['Near Miss View']) &&
                <li className="list-group-item px-0 py-0 sidebar-item mb-3 has-children border-0">
                  <a href="#log-sec" className="text-heading text-decoration-none lh-1 d-flex sidebar-link align-items-center py-5 px-6 position-relative" data-bs-toggle="collapse" aria-expanded="false" title="Logs">
                    <span className="sidebar-item-icon d-inline-block w-40px text-muted">
                      <i className="fas fa-history" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">External Logs</span>
                    <span className="position-absolute top-50 end-5 translate-middle-y"><i className="far fa-angle-down" /></span>
                  </a>
                  <div className={`collapse menu-collapse ${isActiveLogs(activeItem) ? 'show' : ''}`} id="log-sec">
                    <ul className="sub-menu list-unstyled">
                      <li className= {`sidebar-item ${isActiveLogsList(activeItem) ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/near-miss-logs`} title="Order List 1">
                            Near Miss Error  
                        </a>
                      </li>
                      {hasPermission(['File Manager']) &&
                      <li className= {`sidebar-item ${activeItem ==='/file-manager' ? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/file-manager`} title="Order List 1">
                            File Manager 
                        </a>
                      </li>
                      }
                    </ul>
                  </div>
                </li>
                }
                {hasPermission(['Meeting View']) &&
                <li className="list-group-item px-0 py-0 sidebar-item mb-3 has-children border-0">
                  <a href="#meeting-sec" className="text-heading text-decoration-none lh-1 d-flex sidebar-link align-items-center py-5 px-6 position-relative" data-bs-toggle="collapse" aria-expanded="false" title="Logs">
                    <span className="sidebar-item-icon d-inline-block w-40px text-muted">
                      <i className="fas fa-calendar" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Meeting</span>
                    <span className="position-absolute top-50 end-5 translate-middle-y"><i className="far fa-angle-down" /></span>
                  </a>
                  <div className={`collapse menu-collapse ${activeItem === '/meetings' || activeItem === '/meetings/add' || activeItem === '/meetings/staff' || activeItem === '/notes' ? 'show' : ''}`} id="meeting-sec">
                    <ul className="sub-menu list-unstyled">
                      {hasPermission(['Management Meeting']) &&
                      <li className= {`sidebar-item ${activeItem === '/meetings'? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/meetings`} title="Order List 1">
                           Management Meetings 
                        </a>
                      </li>
                      }
                      {hasPermission(['Staff Meeting']) &&

                      <li className= {`sidebar-item ${activeItem === '/meetings/staff'? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/meetings/staff`} title="Order List 1">
                           Staff Meetings 
                        </a>
                      </li>
                      }
                      {hasPermission(['Note Panel']) &&

                      <li className= {`sidebar-item ${activeItem === '/notes'? 'active' : ''}`}>
                        <a className="sidebar-link pe-5 ps-8 py-5 lh-1 text-decoration-none fs-14px fw-semibold" 
                          href={`/notes`} title="notes">
                          Notes 
                        </a>
                      </li>
                      }
                    </ul>
                  </div>
                </li>
                }

                <li className={`list-group-item px-0 py-0 sidebar-item mb-3 border-0 `}>
                  <a href="https://inventory.myprivatechemist.com" target='_blank' rel="noreferrer" className="text-heading text-decoration-none lh-1 sidebar-link py-5 px-6 d-flex align-items-center" title="Dashboard">
                    <span className="sidebar-item-icon w-40px d-inline-block text-muted">
                      <i className="fa fa-bar-chart" />
                    </span>
                    <span className="sidebar-item-text fs-14px fw-semibold">Inventory</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </aside>
      </div>




    </>
  )
}

export default Sidebar