import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, del } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import Pagination from '../../components/Pagination';
import toast, { Toaster } from 'react-hot-toast';
import OrderTable from "./OrderTable";
import Breadcrumbs from "../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import {  Button,Modal,notification } from 'antd';
import OrderStatsModal from "./OrderStatsModal";
import usePermissionCheck from "../../hooks/usePermissionCheck";

const OrderList = () => {
    const canView= usePermissionCheck(['Order Detail']);
    const [filters, setFilters] = useState({
        orderId: '',
        customer: '',
        status: 'All',
        type:'All',
        limit: '20',
        dateRange: [null, null],
        sortOrder: 'newest',
        duplicate:'All',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [isOrderCall, setIsOrderCall] = useState(false);
    const [orderMessage, setOrderMessage] = useState('');
    const [orderErrors, setOrderErrors] = useState([]); 
    const [orders, setOrders] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [limit, setLimit] = useState(20);
    const [orderCount, setOrderCount] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isStatsOpen, setIsStatsOpen] = useState(false);
    const [allStats, setAllStats] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                await fetchData();
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false); // Set to false to indicate loading is complete
            }
        };
    
        loadData();
    }, [currentPage]);
    useEffect(() => {
        fetchData();
    }, [filters]);
    
    
    const fetchData = async () => {

        try {
            const Body = {
              page: currentPage,
              ...filters,
              startDate: filters.dateRange[0] ? filters.dateRange[0].toISOString() : null,
              endDate: filters.dateRange[1] ? filters.dateRange[1].toISOString() : null
            };
            const res = await post(`api/orders/list`, Body);
            if (res.success) {
              setOrders(res.orders);
              setTotalPages(res.totalPages);
              setNotFound(false);
              setOrderCount(res.orderCount);
            } else {
              setOrders([]);
              setTotalPages(1);
              setNotFound(true);
            }
        } catch (error) {
            setOrders([]);
            setTotalPages(1);
            setNotFound(true);
            console.error(error.message);
        }
    };


    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });

    };
    const handleDateRangeChange = (dates) => {
        const [start, end] = dates;
        setFilters({
            ...filters,
            dateRange: [start, end]
        });
    };
    const handleSortOrderChange = (e) => {
        setFilters({
            ...filters,
            sortOrder: e.target.value
        });
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value));
        setFilters({
            ...filters,
            [event.target.name]: event.target.value
        });
    };
    const fetchOrdersManually = async () => {

        try {
    
            setIsOrderCall(true);
            
            const res = await get(`api/ebay/orders/fetch`);
            setIsOrderCall(false);
            if (res.success) {
              setOrderMessage(res.message);
              fetchData();
            } else {
                setOrderMessage(res.message);
                setOrderErrors(res.errors);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const resetFilters = () => {
        setFilters({
            orderId: '',
            customer: '',
            status: 'All',
            type: 'All',
            limit: '20',
            dateRange: [null, null], // Ensure dateRange is properly reset
            sortOrder: 'newest',
            duplicate:'All'
        });
        setCurrentPage(1); 
        setOrders([]); 
        setTotalPages(1); 
        fetchData();
    };

    // Function to handle modal confirmation
    const sendOrderRM = async () => {

        try {
            const res = await get(`api/orders/prepare/royalmail`);
            console.log(res.stats)
            if(res.success){
                setAllStats(res.stats);
                setIsStatsOpen(true);
            }else{
                notification.error({ message: 'Failed', description: 'Failed to send to royal mail' });
            }

        } catch (error) {
            console.log(error)
            notification.error({ message: 'Failed', description: 'Failed to send to royal mail' });
        }
    };
    
    const confirmRM = () => {
        Modal.confirm({
          title: 'Are you sure?',
          content: 'This action will process orders for royalmail',
          okText: 'Yes',
          okType: 'primary',
          cancelText: 'Cancel',
          cancelType:'danger',
          onOk: () => sendOrderRM(),
        });
    };

    const statsOpenModal = () => setIsStatsOpen(true);
    const statsCloseModal = () => setIsStatsOpen(false);


  return (
    <>
        <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="col-sm-6 mb-8 mb-sm-0">
                    <h2 className="fs-4 mb-0">All Orders (<span className="fs-4 text-primary">{orderCount}</span>)</h2>
                    <Breadcrumbs />
                </div>
                <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                    {/* <button className="btn btn-primary btn-sm" onClick={fetchOrdersManually} disabled={isOrderCall}>
                        {isOrderCall ? (
                        <>
                            <i className="fa fa-spinner fa-spin"></i> Please wait...
                        </>
                        ) : (
                        'Fetch Orders'
                        )}
                    </button>
                    {orderMessage && <p className="text-primary">Order fetched successfully</p>}
                    {orderErrors.length > 0 && 
                        <ul>
                            {orderErrors.map((error, index) => (
                                <li className="text-danger" key={index}>{error}</li>
                            ))}
                        </ul>
                    } */}
                    {usePermissionCheck(['Send to RM']) && 
                        <button className="btn btn-primary btn-sm"  onClick={() => confirmRM()}>
                            SEND RM <i className="fa fa-fighter-jet"></i>
                        </button>
                    }




                </div>
            </div>
            <div className="card mb-4 rounded-4 p-7">
                <div className="card-header bg-transparent px-0 pt-0 pb-7">
                <div className="row align-items-center justify-content-between mb-4">

                    <div className="col-md-3 col-3 col-sm-12 mr-auto mb-md-0 mb-6">
                        <input type="text" placeholder="Search by order id..." name="orderId" value={filters.orderId} onChange={handleFilterChange}  className="form-control bg-input border-0" />
                    </div>
                    <div className="col-lg-3 col-3 col-sm-12 mr-auto mb-md-0 mb-6">
                        <input type="text" placeholder="Search by customer..." name="customer" value={filters.customer} onChange={handleFilterChange}  className="form-control bg-input border-0" />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="type" value={filters.type} onChange={handleFilterChange}>
                            <option value='All'> Type</option>
                            <option value='GSL'>GSL</option>
                            <option value='P-Med'>P-Med</option>
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="status" value={filters.status} onChange={handleFilterChange}>
                            <option value='All'> Status</option>
                            <option value='NOT_STARTED'>ERROR</option>
                            <option value='SEND_TO_RM'>READY FOR RM</option>
                            <option value='ASSESSMENT_REQUIRED'>ASSESSMENT INCOMPLETE</option>
                            <option value='PENDING'>PENDING P APPROVAL</option>
                            <option value='PROCESSED'>SENT TO RM</option>
                            <option value='SHIPPED'>DISPATCHED</option>
                            <option value='CANCELED'>CANCELED</option>
                        </select>
                    </div>
                </div>
                <div className="row align-items-center justify-content-between">

                    <div className="col-lg-3 col-sm-6 col-3">
                        <DatePicker
                            selected={filters.dateRange[0]}
                            onChange={handleDateRangeChange}
                            startDate={filters.dateRange[0]}
                            endDate={filters.dateRange[1]}
                            selectsRange
                            isClearable
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select date range"
                            className="form-control bg-input border-0"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="sortOrder" value={filters.sortOrder} onChange={handleSortOrderChange}>
                            <option value="newest">Newest to Oldest</option>
                            <option value="oldest">Oldest to Newest</option>
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="duplicate" value={filters.sortOrder} onChange={handleFilterChange}>
                            <option value="All">No Duplicate</option>
                            <option value="duplicate">Duplicate</option>
                        </select>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="limit" value={filters.limit} onChange={handleLimitChange}>
                            <option value="10">Show 10</option>
                            <option value="20">Show 20</option>
                            <option value="50">Show 50</option>
                            <option value="100">Show 100</option>
                        </select>
                    </div>
                    <div className="col-lg-1 col-md-6 col-sm-6 col-3">
                        <Link className="mt-4 text-danger" onClick={resetFilters}><i className="fa fa-sync" style={{fontSize:'20px',marginTop:'12px'}}></i></Link>
                    </div>

                </div>
                </div>
                <div className="card-body px-0 pt-7 pb-0">
                    {isLoading  ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ):(
                        orders.length > 0 && <OrderTable orders={orders} canView={canView} />
                    )}
                    {!isLoading && isNotFound && 
                        <div className="text-center">
                        <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
                        <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                        </div>
                    }
                </div>
            </div>
            {totalPages && totalPages > 1 && 
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            }
            <OrderStatsModal allStats={allStats} statsCloseModal={statsCloseModal} isStatsOpen={isStatsOpen}  />
        </Layout>
    </>
  )
}

export default OrderList