import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout'
import { post, get } from '../../services/ApiService';
import "../custom-style.css";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";

const UserAdd = () => {
    const [isLoading, setIsLoading] = useState(false); 
    const [roles, setRoles] = useState([]);
    const initialFormData = {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        role: '',
    };

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const res = await get(`api/roles/list`);
            console.log(res);
            if (res.success) {
                setRoles(res.roles);
            }
        } catch (error) {
            setRoles([]);
            console.error(error.message);
        }
    };

    const [formData, setFormData] = useState(initialFormData);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const res = await post(`api/users/add`, formData);
            console.log(res)
            if (res.success) {
                toast.success(res.message);
                setIsLoading(false);
                setFormData(initialFormData);

            } else {
                toast.error(res.error);
              setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

  return (
    <>
    <Layout>
        <div>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-0">
                            Add User
                        </h2>
                        <Breadcrumbs/>
                    </div>

                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-8 rounded-4">

                            <div className="card-body p-7">
                            <form className="form-border-1" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-6 mb-8">
                                        <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                            First Name
                                        </label>
                                        <input type="text" name="firstname" placeholder="Enter firstname" className="form-control" value={formData.firstname} onChange={handleChange}  required />
                                    </div>
                                    <div className="col-6 mb-8">
                                        <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                            Last Name
                                        </label>
                                        <input type="text" name="lastname" placeholder="Enter lastname" className="form-control" value={formData.lastname} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 mb-8">
                                        <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                            Email
                                        </label>
                                        <input type="email" name="email" placeholder="Enter email address" className="form-control" value={formData.email} onChange={handleChange} required />
                                    </div>
                                    <div className="col-6 mb-8">
                                        <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                            Phone
                                        </label>
                                        <input type="number" name="phone" placeholder="Enter phone number" className="form-control" value={formData.phone} onChange={handleChange} required />
                                    </div>
                                </div>


                                <div className="mb-8">
                                    <label className="mb-4 fs-13px ls-1 fw-bold text-uppercase" htmlFor="currency">Role</label>
                                    <select className="form-select" name="role" value={formData.role} id="currency" onChange={handleChange} required>
                                        <option value="">Choose</option>
                                        {roles && roles.map((role,index)=>(
                                            <option value={role._id}>{role.name}</option>
                                        ))}
                                    </select>
                                </div>


                                <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end">
                                    <button type="submit" className="btn btn-primary"  disabled={isLoading}>
                                        {isLoading && <i className="fa fa-spinner fa-spin"></i>}   Create
                                    </button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default UserAdd