import React, { useState, useEffect, useRef } from "react";
import Layout from '../../components/Layout'
import { get, post, del } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Button, Popconfirm} from 'antd';
import usePermissionCheck from "../../hooks/usePermissionCheck";

const QuestionList = () => {
    const canEditQ= usePermissionCheck(['Question Edit']);
    const canDeleteQ = usePermissionCheck(['Question Delete']);
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [questions, setQuestions] = useState([]);


    useEffect(() => {
        fetchData();
      }, []);
    const fetchData = async () => {
        try {
            setIsLoading(true);
          const res = await get('api/questions/list');
          setIsLoading(false);
          if (res.success) {
            setQuestions(res.questions);
            setNotFound(false)
          } else {
            setQuestions([]);
            setNotFound(true)
          }
        } catch (error) {
            setIsLoading(false);
            setNotFound(true)
            console.log(error)
        }
    };



    const handleQuestionDelete = async (id) => {
        try {
            const res = await post(`api/questions/delete/${id}`);
            console.log(res)
            if (res.success) {
                toast.success(res.message);
                fetchData();
            } else {
                toast.error(res.error);
            }
        } catch (error) {
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };
  return (
    <>
    <Layout>
        <div className="row mb-9 align-items-center justify-content-between">
            <div className="col-md-4 mb-8 mb-md-0">
                <h2 className="fs-4 mb-0">Question List</h2>
                <Breadcrumbs />
            </div>
            <div className="col-md-8 d-flex flex-wrap justify-content-md-end">
  
            </div>
        </div>
        <div className="row mb-9 align-items-center justify-content-between">
            <div className="card mb-4 rounded-4 p-7">
                <div className="card-body px-0 pt-7 pb-0">
                    <div className="row">
                        <div className="col-6">
                            <h6 className="fs-4 mb-0">Questions</h6>
                            <p>List of questions which will be used in web page and ebay message assessment</p>
                        </div>
                        <div className="col-6 text-end">
                            {usePermissionCheck(['Question Create']) && 
                                <a href={`/questions/add`} className="btn btn-primary btn-sm">
                                    <i className="fa fa-plus"></i> Add
                                </a>
                            }

                        </div>
                    </div>
                    <hr/>
                    {isLoading ? (
                    <div className='text-center'>
                        <Loader />
                    </div>
                    ):(
                    <div className="table-responsive">
                        {questions && questions.length >0 &&
                        <>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                            {questions.map((question, index) => (
                                <div className="accordion-item mb-4">
                                    <h2 className="accordion-header" id="flush-headingOne">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-${index}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                         
                                          <div className="d-flex">
                                            <div className=""> {question.seq} -  {question.question}</div>
                                            <div className="" style={{marginLeft:'10px'}}>
                                            {question.tags && 
                                                question.tags.map((tag) => (
                                                    <span className="badge bg-dark  m-2">{tag}</span>
                                                ))
                                            }
                                            </div>
                                          </div>
                                        </button>
                                    </h2>
                                    <div id={`flush-collapse-${index}`} className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <div>
                                            <table  className="table table-bordered" cellSpacing="1px" padding="20px">
                                                <tbody>
                                                    <tr>
                                                        <th width="20%">Input</th>
                                                        <td width="80%">{question.type}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Options</th>
                                                        <td width="80%">
                                                        {question.options && question.options.length > 0 ?(
                                                            question.options.map((option, index) => (
                                                                    <><span>{index +1} - {option}</span><br/></>
                                                                ))
                                                                ):(
                                                                    <span className="text-muted">N/A</span>
                                                                )

                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Description</th>
                                                        <td width="80%"><span style={{fontWeight:'400'}} dangerouslySetInnerHTML={{ __html: question.description }} /></td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Answer</th>
                                                        <td width="80%">{question.correctAnswer}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Answer warning</th>
                                                        <td width="80%">{question.warning}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">eBay Message</th>
                                                        <td width="80%">{question?.messageQuestion}</td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">Action</th>
                                                        <td width="80%"> 
                                                            {canEditQ &&                                                   
                                                            <Link to={`/questions/edit/${question._id}`} class=" py-4 px-5 btn-xs fs-20px me-4">
                                                                <i class="far fa-pen me-2"></i>
                                                            </Link>
                                                            }
                                                            {canDeleteQ && 
                                                            <>
                                                                {!question.isDeleteAble && <span>Question assigned </span>}
                                                                {question.isDeleteAble && 
                                                                    <Popconfirm
                                                                        title="Delete the Question"
                                                                        description="Are you sure ?"
                                                                        onConfirm={() => handleQuestionDelete(question._id)}
                                                                        okText="Yes"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button ><i className="fa fa-trash"></i></Button>
                                                                    </Popconfirm>
                                                                } 
                                                            </>
                                                            }

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </>
                        }
                        {!isLoading && questions.length === 0 && 
                            <div className="text-center">
                            <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} />
                            <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                            </div>
                        }
                    </div>
                    )}
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default QuestionList