// hooks/usePermissionCheck.js
import { usePermissions } from "../redux/PermissionsContext";

const usePermissionCheck = (requiredPermissions) => {
  const { rolePermissions } = usePermissions();

  return requiredPermissions.every(permission => rolePermissions.includes(permission));
};

export default usePermissionCheck;
