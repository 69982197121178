import React, { useState, useEffect } from 'react';
import { Modal, Select, Input, DatePicker, message } from "antd";
import moment from 'moment';

const { Option } = Select;

const AddShiftModal = ({ isModalVisible, handleCancel, handleSaveShift, users, editShift }) => {
  const [selectedUser, setSelectedUser] = useState('');
  const [shiftDate, setShiftDate] = useState(null);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const onSave = () => {
    if (!selectedUser || !shiftDate || !startTime || !endTime) {
      message.error("Please fill in all required fields.");
      return;
    }
    const formattedShiftDate = shiftDate.format('YYYY-MM-DD'); // Format to 'YYYY-MM-DD'

    console.log("Saving Shift Date:", formattedShiftDate);
    handleSaveShift({ selectedUser, shiftDate:formattedShiftDate, startTime, endTime, editShiftId: editShift?._id });
  };
  useEffect(() => {
    // Reset state when modal opens
    setSelectedUser(editShift?.userId || '');
    setShiftDate(editShift?.shiftDate ? moment(editShift.shiftDate) : null); // Ensure this is a moment object
    setStartTime(editShift?.startTime || '');
    setEndTime(editShift?.endTime || '');
  }, [editShift, isModalVisible]);

  return (
    <Modal
      title={editShift ? "Edit Shift" : "Add New Shift"}
      open={isModalVisible}
      onOk={onSave}
      onCancel={handleCancel}
      okText="Save"
      cancelText="Cancel"
    >
      <div className="shift-form">
        <div className="col-12">
          <label>Select User:</label>
          <Select
            value={selectedUser}
            onChange={(value) => setSelectedUser(value)}
            style={{ width: '100%', marginBottom: '10px' }}
            placeholder="Select User"
          >
            {users.map(user => (
              <Option key={user._id} value={user._id}>
                {user.firstname} {user.lastname}
              </Option>
            ))}
          </Select>
        </div>

        <div className="col-12 mt-4">
          <label>Select Shift Date:</label><br />
          <DatePicker
            value={shiftDate ? shiftDate : null} // Ensure moment instance is used
            onChange={(date) => {
              console.log("Selected date:", date ? date.format('YYYY-MM-DD') : null); // Log formatted selected date
              setShiftDate(date); // Update state with selected date
            }}
            style={{ width: '100%', marginBottom: '10px' }}
            placeholder="Select Shift Date"
          />
        </div>

        <div className="row">
          <div className="col-6 mt-4">
            <label>Start Time:</label>
            <Input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </div>
          <div className="col-6 mt-4">
            <label>End Time:</label>
            <Input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              style={{ width: '100%', marginBottom: '10px' }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddShiftModal;
