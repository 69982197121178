import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './index.css'

const   Unauthorized = () => {

  return (
    <>
    <div>
    <main>
        <div className="container">
        <div className="row">
            <div className="col-12 text-center">
                <img src="/assets/images/notfound.jpg" style={{width:'700px'}} />
               
            </div>
            <div className="col-12 text-center">
                <Link to={`/`} className="btn btn-primary w-20">Home</Link>
            </div>
        </div>
        </div>
    </main>
    </div>

    </>
  )
}

export default Unauthorized