import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Checkbox, Spin, message } from 'antd';
import { get, post } from '../../services/ApiService';
import { useParams } from 'react-router-dom';

const Permission = () => {
    const { id } = useParams(); // Role ID from URL
    const [isLoading, setIsLoading] = useState(true);
    const [permissionsBySection, setPermissionsBySection] = useState({}); // Permissions grouped by section
    const [rolePermissions, setRolePermissions] = useState([]); // Permissions assigned to the role

    useEffect(() => {
        fetchPermissions();
    }, []);

    // Fetch all permissions and the permissions assigned to the role
    const fetchPermissions = async () => {
        try {
            setIsLoading(true);
            const res = await get(`api/roles/${id}/permissions`);
            console.log(res);
    
            if (res.success) {
                const permissions = res.permissions;
                const rolePermissionsIds = Array.isArray(res.rolePermissions) ? res.rolePermissions.map(p => p._id) : [];
    
                // Group permissions by section
                const groupedPermissions = permissions.reduce((acc, permission) => {
                    const section = permission.section || 'General';
                    if (!acc[section]) acc[section] = [];
                    acc[section].push({
                        ...permission,
                        checked: rolePermissionsIds.includes(permission._id) // Mark as checked if assigned to the role
                    });
                    return acc;
                }, {});
    
                setPermissionsBySection(groupedPermissions);
                setRolePermissions(rolePermissionsIds);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            message.error('Failed to fetch permissions');
        }
    };
    
    

    // Handle checkbox change for assigning/removing permissions
    const handlePermissionChange = async (permissionId, checked) => {
        try {
            const endpoint = checked 
                ? `api/roles/${id}/assign-permission` 
                : `api/roles/${id}/remove-permission`;
            const res = await post(endpoint, { permissionId });

            if (res.success) {
                setRolePermissions(prev =>
                    checked ? [...prev, permissionId] : prev.filter(p => p !== permissionId)
                );
                message.success(`Permission ${checked ? 'assigned' : 'removed'} successfully!`);
            } else {
                message.error('Failed to update permission');
            }
        } catch (error) {
            message.error('An error occurred');
        }
    };

    return (
        <Layout>
            <div>
                <div className="row mb-9 align-items-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-6 mb-8 mb-sm-0">
                                <h2 className="fs-4 mb-0">Permissions</h2>
                                <Breadcrumbs/>
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '50px' }}>
                            <div className="card">
                                <div className="card-body">
                                {isLoading ? (
                                <Spin />
                            ) : (
                                Object.keys(permissionsBySection).map((section) => (
                                    <div key={section} className="col-12 mb-4">
                                        <h6 className="text-primary">{section}</h6>
                                        <div className="row">
                                            {permissionsBySection[section].map((permission) => (
                                                <div key={permission._id} className="col-md-3">
                                                    <Checkbox
                                                        checked={rolePermissions.includes(permission._id)}
                                                        onChange={(e) => handlePermissionChange(permission._id, e.target.checked)}
                                                    >
                                                        {permission.name}
                                                    </Checkbox>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Permission;
