import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import "react-big-calendar/lib/css/react-big-calendar.css";
import Layout from '../../components/Layout';
import { get } from '../../services/ApiService';
import { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Modal, Button } from 'antd'; // Keep Antd for Modal and Button
import AddEventForm from "./AddEventForm";
import "./index.css";

// Initialize localizer
moment.updateLocale('en', {
    week: {
        dow: 1 // 1 represents Monday as the start of the week
    }
});
const localizer = momentLocalizer(moment);

const Event = () => {
    const [users, setUsers] = useState([]);
    const [events, setEvents] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [filterDate, setFilterDate] = useState(null); // Initialize to null

    useEffect(() => {
        fetchEvents();
    }, []);

    const fetchEvents = async () => {
        try {
            setIsLoading(true);
            const res = await get(`api/shifts/event/list`);
            setIsLoading(false);
            if (res.success) {
                setEvents(res.events);
                setUsers(res.users);
            } else {
                setEvents([]);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedEvent(null);
    };

    const handleModalOk = () => {
        setIsModalOpen(false);
    };

    const handleEventClick = (event) => {
        setSelectedEvent(null);
        const foundEvent = events.find((e) => e._id === event.shiftId);
        if (foundEvent) {
            setSelectedEvent(foundEvent); // Set the selected event
            setIsModalOpen(true);         // Then open the modal
        } else {
            setSelectedEvent(null);       // Clear if no event is found
        }
    };

    const handleDateChange = (event) => {
        const selectedValue = event.target.value;
        setFilterDate(selectedValue ? selectedValue : null); // Update filterDate to null if no date is selected
    };

    // Filter events based on the selected date
    const filteredEvents = filterDate 
        ? events.filter(event => {
            const eventStart = moment(`${event.startDate} ${event.startTime}`, 'YYYY-MM-DD HH:mm').startOf('day');
            const eventEnd = moment(`${event.startDate} ${event.endTime}`, 'YYYY-MM-DD HH:mm').endOf('day');
            const selectedMoment = moment(filterDate); // Convert the filter date to a moment object

            return eventStart.isSame(selectedMoment, 'day') || eventEnd.isSame(selectedMoment, 'day');
        })
        : events; // If filterDate is null, show all events

    return (
        <>
            <Layout>
                <Toaster position="top-center" reverseOrder={false} />
                <div className="row mb-9 align-items-center justify-content-between">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-0">Events</h2>
                        <Breadcrumbs />
                    </div>
                    <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                        <button className="btn btn-primary mt-4" onClick={() => setIsModalOpen(true)}>
                            Add New Event
                        </button>
                    </div>
                </div>

                {/* Simple Date Picker */}


                <div className="card mb-4 rounded-4 p-7">
                    <div className="card-header bg-transparent px-0 pt-0 pb-7">
                    <div className="text-center">
                        <input
                            className="col-12"
                            type="date"
                            id="datePicker"
                            value={filterDate ? filterDate : ''} // Show empty when no date is selected
                            onChange={handleDateChange}
                            style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </div>
                    </div>
                    <div className="card-body px-0 pt-7 pb-0">
                        {filteredEvents.length > 0 ? (
                            <Calendar
                                localizer={localizer}
                                events={filteredEvents.map(event => {
                                    const eventStart = moment(`${event.startDate} ${event.startTime}`, 'YYYY-MM-DD HH:mm').toDate();
                                    const eventEnd = moment(`${event.startDate} ${event.endTime}`, 'YYYY-MM-DD HH:mm').toDate();

                                    return {
                                        title: `${event.eventName}`,
                                        allDay: false,
                                        start: eventStart,
                                        end: eventEnd,
                                        shiftId: event._id,
                                        isOff: event.isOff
                                    };
                                })}
                                onSelectEvent={handleEventClick}
                                startAccessor="start"
                                endAccessor="end"
                                defaultView="week"
                                step={30} // Set the step to 30 minutes
                                timeslots={2} // Display two time slots per hour
                                min={new Date(2024, 9, 22, 8, 0)}
                                eventPropGetter={(event) => ({
                                    style: {
                                        backgroundColor: event.isOff ? 'red' : '', // Set color based on isOff
                                        color: 'white' // Text color for readability
                                    }
                                })}
                            />
                        ) : (
                            <p>No event found for the selected date!</p>
                        )}
                    </div>
                </div>

                <Modal
                    title={selectedEvent ? "Edit Event" : "Add New Event"}
                    open={isModalOpen}
                    onOk={handleModalOk}
                    onCancel={handleModalClose}
                    footer={null}
                >
                    <AddEventForm
                        selectedDate={selectedDate}
                        onClose={handleModalClose}
                        onEventAdded={fetchEvents}
                        users={users}
                        existingEvent={selectedEvent}
                    />
                </Modal>
            </Layout>
        </>
    );
};

export default Event;
