// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { NavigationProvider,useNavigation } from './context/NavigationContext';
import ProtectedRoute from './redux/ProtectedRoute';
import { PermissionsProvider } from './redux/PermissionsContext';
import PrivateRoute from './pages/auth/PrivateRoute';
import Dashboard from './pages/Dashboard';
import ProductList from './pages/Products/ProductList';
import ProductEdit from './pages/Products/ProductEdit';
import QuestionAdd from './pages/Questions/QuestionAdd';
import QuestionList from './pages/Questions/QuestionList';
import QuestionEdit from './pages/Questions/QuestionEdit';
import AssignQuestions from './pages/Products/AssignQuestions';
import OrderList from './pages/Orders/OrderList';
import OrderDetail from './pages/Orders/OrderDetail';
import OrderEdit from './pages/Orders/OrderEdit';
import PharmaOrders from './pages/Orders/PharmaOrders';
import Questionnaire from './pages/assessments/Questionnaire';
import VariationList from './pages/Variations/VariationList';
import OrderAssessment from './pages/Orders/OrderAssessment';
import Login from './pages/auth/Login';
import Profile from './pages/Profile';
import Notes from './pages/notes/Notes';
import DemoAssessment from './pages/assessments/DemoAssessment';
import Orders from './pages/Orders/AssessmentOrder/Orders';
import DemoChat from './pages/Orders/DemoChat';
import Assessment from './pages/Orders/Assessment/Assessment';
import EbayQuestionDemo from './pages/assessments/EbayQuestionDemo';
import Add from './pages/externalLogs/nearMissLogs/Add';
import List from './pages/externalLogs/nearMissLogs/List';
import Edit from './pages/externalLogs/nearMissLogs/Edit';
import FileList from './pages/externalLogs/fileManager/FileList';
import UserList from './pages/users/UserList';
import UserAdd from './pages/users/UserAdd';
import UserEdit from './pages/users/UserEdit';
import MeetingAdd from './pages/meetings/meetingAdd';
import MeetingList from './pages/meetings/meetingList';
import StaffMeeting from './pages/meetings/StaffMeeting';
import MeetingEdit from './pages/meetings/MeetingEdit';
import MeetingDetail from './pages/meetings/MeetingDetail';
import SiteSetting from './pages/settings/SiteSetting';
import AssessmentTracker from './pages/Orders/Assessment/AssessmentTracker';
import Shifts from './pages/shift/Shifts';
import Event from './pages/shift/Event';
import Roles from './pages/RolePermission/Roles';
import Permission from './pages/RolePermission/Permission';
import Unauthorized from './pages/notfound/Unauthorized';
const App = () => {

    return (
        <PermissionsProvider>
        <Router>
            <Routes>
                
                <Route path='/login' element={<Login/>} />
                <Route path='/unauthorized' element={<Unauthorized/>} />
                <Route path='/questionnaire/:id' element={<Questionnaire/>} />
                <Route path='/' element={<PrivateRoute><Dashboard/></PrivateRoute>} />

                {/* Product routes  */}
                <Route path='/products' element={<ProtectedRoute component={ProductList} requiredPermissions={['Product View']} />} />
                <Route path='/products/edit/:id' element={<ProtectedRoute component={ProductEdit} requiredPermissions={['Product Edit']} />} />
                <Route path='/products/assign-question/:id' element={<ProtectedRoute component={AssignQuestions} requiredPermissions={['Assign Question']} />} />
                <Route path='/variations' element={<ProtectedRoute component={VariationList} requiredPermissions={['Variations']} />} />

                {/* Question Routes  */}
                <Route path='/questions/add' element={<ProtectedRoute component={QuestionAdd} requiredPermissions={['Question Create']} />} />
                <Route path='/questions' element={<ProtectedRoute component={QuestionList} requiredPermissions={['Question View']} />} />
                <Route path='/questions/edit/:id' element={<ProtectedRoute component={QuestionEdit} requiredPermissions={['Question Edit']} />} />

                {/* Order routes  */}
                <Route path='/orders' element={<ProtectedRoute component={OrderList} requiredPermissions={['All Orders']} />} />
                <Route path='/orders/edit/:id' element={<ProtectedRoute component={OrderEdit} requiredPermissions={['Order Edit']} />} />
                <Route path='/orders/pharma-medicine' element={<ProtectedRoute component={PharmaOrders} requiredPermissions={['Pending P Approval']} />} />
                <Route path='/orders/:id' element={<ProtectedRoute component={Assessment} requiredPermissions={['All Orders']} />} />
                <Route path='/assessment/tracker/:id' element={<ProtectedRoute component={AssessmentTracker} requiredPermissions={['Tracker']} />} />
                <Route path='/orders/assessment-required' element={<ProtectedRoute component={Orders} requiredPermissions={['Assessment Incomplete']} />} />


                <Route path='/assessment/demo/:id' element={<PrivateRoute><DemoAssessment/></PrivateRoute>} />
                <Route path='/chat/demo' element={<PrivateRoute><DemoChat/></PrivateRoute>} />
                <Route path='/assessment/demo' element={<PrivateRoute><Assessment/></PrivateRoute>} />
                <Route path='/ebay/question/demo/:id' element={<PrivateRoute><EbayQuestionDemo/></PrivateRoute>} />

                {/* Logs routes  */}
                <Route path='/near-miss-logs/add' element={<ProtectedRoute component={Add} requiredPermissions={['Near Miss View']} />} />
                <Route path='/near-miss-logs' element={<ProtectedRoute component={List} requiredPermissions={['Near Miss Create']} />} />
                <Route path='/near-miss-logs/edit/:id' element={<ProtectedRoute component={Edit} requiredPermissions={['Near Miss Edit']} />} />
                <Route path='/file-manager' element={<ProtectedRoute component={FileList} requiredPermissions={['File Manager']} />} />

                {/* User routes  */}
                <Route path='/users' element={<ProtectedRoute component={UserList} requiredPermissions={['User View']} />} />
                <Route path='/users/add' element={<ProtectedRoute component={UserAdd} requiredPermissions={['User Create']} />} />
                <Route path='/users/edit/:id' element={<ProtectedRoute component={UserEdit} requiredPermissions={['User Edit']} />} />

                {/* Meeting routes  */}
                <Route path='/meetings/add' element={<ProtectedRoute component={MeetingAdd} requiredPermissions={['Meeting Create']} />} />
                <Route path='/meetings' element={<ProtectedRoute component={MeetingList} requiredPermissions={['Management Meeting']} />} />
                <Route path='/meetings/staff' element={<ProtectedRoute component={StaffMeeting} requiredPermissions={['Staff Meeting']} />} />
                <Route path='/meetings/edit/:id' element={<ProtectedRoute component={MeetingEdit} requiredPermissions={['Meeting Edit']} />} />
                <Route path='/meetings/detail/:id' element={<ProtectedRoute component={MeetingDetail} requiredPermissions={['Meeting View']} />} />
                <Route path='/notes' element={<ProtectedRoute component={Notes} requiredPermissions={['Note Panel']} />} />



                {/* setting routes  */}
                <Route path='/site-setting' element={<ProtectedRoute component={SiteSetting} requiredPermissions={['Site Setting']} />} />
                <Route path='/events' element={<ProtectedRoute component={Event} requiredPermissions={['Events']} />} />
                <Route path='/profile' element={<ProtectedRoute component={Profile} requiredPermissions={['Profile Setting']} />} />


                {/* Role routes  */}
                <Route path='/roles' element={<ProtectedRoute component={Roles} requiredPermissions={['Role Create']} />} />
                <Route path='/roles/:id/permissions' element={<ProtectedRoute component={Permission} requiredPermissions={['Assign Permission']} />} />


            </Routes>
        </Router>
        </PermissionsProvider>

    );
};

export default App;
