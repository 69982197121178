import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout';
import { get, post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { Modal, Button, Select, Input, message } from "antd";
import AddShiftModal from "./AddShiftModal";
import EventModal from "./EventModal";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-datepicker/dist/react-datepicker.css';

const localizer = momentLocalizer(moment);
const { Option } = Select;

const Shifts = () => {
    const [users, setUsers] = useState([]);
    const [shifts, setShifts] = useState([]);
    const [loading, setIsLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editShift, setEditShift] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await get(`api/shifts`);
            setIsLoading(false);
            if (res.success) {
                setShifts(res.shifts);
                setUsers(res.users);
            } else {
                setShifts([]);
            }
        } catch (error) {
            setShifts([]);
            console.error(error.message);
        }
    };

    const handleSaveShift = async ({ selectedUser, shiftDate, startTime, endTime,editShiftId }) => {
        try {
            const body = {
                userId: selectedUser,
                userName: `${users.find(user => user._id === selectedUser)?.firstname} ${users.find(user => user._id === selectedUser)?.lastname}`,
                shiftDate,
                startTime,
                endTime,
            };
            const res = editShiftId 
            ? await post(`api/shifts/update/${editShiftId}`, body) 
            : await post(`api/shifts/add`, body);
            if (res.success) {
                message.success(editShiftId ? 'Shift updated successfully!' : 'Shift saved successfully!');
                setIsModalVisible(false);
                setEditShift(null); // Reset editShift after saving
                fetchData(); // Refresh shifts data after saving
            } else {
                message.error('Error saving shift!');
            }
        } catch (error) {
            message.error('Error saving shift!');
        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditShift(null);
    };

    const handleSelectEvent = (event) => {
        console.log(event); // Check the selected event details
        const selectedShift = shifts.find(shift => shift._id === event.shiftId); // Find the shift by ID
    
        if (selectedShift) {
            setEditShift(selectedShift); // Set the selected shift in state
            setIsModalVisible(true); // Show the edit modal
        } else {
            console.error('Selected shift not found!');
        }
    };

    return (
        <>
            <Layout>
                <Toaster position="top-center" reverseOrder={false} />
                <div className="row mb-9 align-items-center justify-content-between">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-0">User List</h2>
                        <Breadcrumbs />
                    </div>
                    <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                        <button className="btn btn-primary" onClick={showModal}>
                            Add New Shift
                        </button>
                    </div>
                </div>

                <div className="card mb-4 rounded-4 p-7">
                    <div className="card-header bg-transparent px-0 pt-0 pb-7">
                        <div className="row"></div>
                    </div>
                    <div className="card-body px-0 pt-7 pb-0">
                        {shifts.length > 0 ? (
                            <Calendar
                                localizer={localizer}
                                events={shifts.map(shift => {
                                    const shiftStart = moment(`${shift.shiftDate} ${shift.startTime}`, 'YYYY-MM-DD HH:mm').toDate();
                                    const shiftEnd = moment(`${shift.shiftDate} ${shift.endTime}`, 'YYYY-MM-DD HH:mm').toDate();
                                    return {
                                        title: `${shift.userName}`,
                                        start: shiftStart,
                                        end: shiftEnd,
                                        shiftId: shift._id,
                                    };
                                })}
                                onSelectEvent={handleSelectEvent} 
                                startAccessor="start"
                                endAccessor="end"
                                views={['month','week', 'day']} // Ensure week and day views are enabled
                                defaultView="week" // Set default view to week for better time-based visualization
                            />
                        ) : (
                            <p>No shifts available. Please add a shift.</p>
                        )}
                    </div>
                </div>
                {/* <AddShiftModal
                    isModalVisible={isModalVisible}
                    handleCancel={handleCancel}
                    handleSaveShift={handleSaveShift}
                    users={users}
                    editShift={editShift}
                /> */}
                <EventModal 
                isModalVisible={isModalVisible}
                />
            </Layout>
        </>
    );
};

export default Shifts;
