import React, { useState } from "react";
import Layout from "../../components/Layout";
import { post } from "../../services/ApiService";
import "../custom-style.css";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Form, Input, Button, Row, Col, Select } from 'antd';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';

const { TextArea } = Input;

const MeetingAdd = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [form] = Form.useForm();

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);

            const formData = {
                ...values,
                invitees: values.invitees ? values.invitees.split(',').map(email => email.trim()) : [],
                tags: values.tags ? values.tags.split(',').map(tag => tag.trim()) : []
            };

            const res = await post('api/meetings/add', formData);

            if (res.success) {
                toast.success(res.message);
                form.resetFields();
            } else {
                toast.error(res.error);
            }
        
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Layout>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                    <div className="row">
                        <div className="col-sm-6 mb-8 mb-sm-0">
                            <h2 className="fs-4 mb-0">New Meeting</h2>
                            <Breadcrumbs />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-9">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-8 rounded-4">
                                <div className="card-body p-7">
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        onFinish={handleSubmit}
                                    >
                                        <Form.Item
                                            label="Title"
                                            name="title"
                                            rules={[{ required: true, message: 'Please enter the meeting title' }]}
                                        >
                                            <Input placeholder="Type here" className="form-control"  />
                                        </Form.Item>
                                        <Form.Item
                                            label="Date and Time"
                                            name="dateTime"
                                            rules={[{ required: true, message: 'Please select a date and time' }]}
                                        >
                                        <input
                                            type="datetime-local"
                                            style={{ width: '100%' }}
                                            className="form-control"
                                        />
                                        </Form.Item>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Organiser"
                                                    name="organiser"
                                                    rules={[{ required: true, message: 'Please provide organiser name' }]}
                                                >
                                                    <Input placeholder="Type here" className="form-control" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Invitees (comma separated)"
                                                    name="invitees"
                                                    rules={[{ required: true, message: 'Please add Invitees' }]}
                                                >
                                                    <Input placeholder="Add Invitees (comma separated)" className="form-control" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item
                                            label="Agenda"
                                            name="agenda"
                                            rules={[{ required: true, message: 'Please add meeting agenda' }]}
                                        >
                                            <TextArea rows={4} className="form-control" />
                                        </Form.Item>

                                        <Form.Item
                                            label="Tags"
                                            name="tags"
                                        >
                                            <Input placeholder="Type here (comma separated)"  className="form-control"/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Status"
                                            name="status"
                                            rules={[{ required: true, message: 'Please select a status' }]}
                                        >
                                            <Select placeholder="Select a status" className="form-control">
                                                <option value="Pending">Pending</option>
                                                <option value="Scheduled">Scheduled</option>
                                                <option value="Completed">Completed</option>
                                                <option value="Postponed">Postponed</option>
                                                <option value="Draft">Draft</option>
                                                <option value="Cancelled">Cancelled</option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            label="Type"
                                            name="type"
                                            rules={[{ required: true, message: 'Please select a type' }]}
                                        >
                                            <Select placeholder="Select meeting type" className="form-control">
                                                <option value="Management">Management</option>
                                                <option value="Staff">Staff</option>
                                            </Select>
                                        </Form.Item>

                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={isLoading}
                                            >
                                                Publish
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default MeetingAdd;
