import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout'
import {get, post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import toast, { Toaster } from 'react-hot-toast';
import Breadcrumbs from "../../components/Breadcrumbs";
import { useParams, useNavigate } from 'react-router-dom';

const UserEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isNotFound, setNotFound] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [roles, setRoles] = useState([]);


    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        role: '',
    });

    useEffect(() => {
        fetchData();
        fetchRoles()
    }, [id]);

    const fetchRoles = async () => {
        try {
            const res = await get(`api/roles/list`);
            console.log(res);
            if (res.success) {
                setRoles(res.roles);
            }
        } catch (error) {
            setRoles([]);
            console.error(error.message);
        }
    };

    const fetchData = async () => {
        try {

          setIsDataLoading(true);
          const res = await get(`api/users/${id}`);
          setIsDataLoading(false);
          if (res.success) {
            const  data  = res.user;
            setFormData({
                firstname: data?.firstname,
                lastname: data?.lastname,
                email: data?.email,
                phone: data?.phone,
                role: data?.role,
            })
            
          } else {
            setNotFound(true);
          }
        } catch (error) {
          setNotFound(true);
        } finally {
          setIsDataLoading(false);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);

            const res = await post(`api/users/edit/${id}`, formData);
            console.log(res)
            if (res.success) {
                toast.success(res.message);
                setIsLoading(false);
                navigate('/users')
            } else {
                toast.error(res.error);
              setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

  return (
    <>
    <Layout>
        <div>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-sm-6 mb-8 mb-sm-0">
                        <h2 className="fs-4 mb-2">
                            Edit User
                        </h2>
                        <Breadcrumbs/>
                    </div>

                </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xxl-9">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-8 rounded-4">
                            <div className="card-body p-7">
                                {isDataLoading &&
                                    <div className='text-center'>
                                        <Loader />
                                    </div>
                                }
                                {!isDataLoading && !isNotFound &&
                                    <form className="form-border-1" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    First Name
                                                </label>
                                                <input type="text" name="firstname" placeholder="Enter firstname" className="form-control" value={formData.firstname} onChange={handleChange}  required />
                                            </div>
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Last Name
                                                </label>
                                                <input type="text" name="lastname" placeholder="Enter lastname" className="form-control" value={formData.lastname} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Email
                                                </label>
                                                <input type="email" name="email" placeholder="Enter email address" className="form-control" value={formData.email} onChange={handleChange} required />
                                            </div>
                                            <div className="col-6 mb-8">
                                                <label htmlFor="product_title" className="mb-4 fs-13px ls-1 fw-bold text-uppercase" >
                                                    Phone
                                                </label>
                                                <input type="number" name="phone" placeholder="Enter phone number" className="form-control" value={formData.phone} onChange={handleChange} required />
                                            </div>
                                        </div>


                                        <div className="mb-8">
                                            <label className="mb-4 fs-13px ls-1 fw-bold text-uppercase" htmlFor="currency">Role</label>
                                            <select className="form-select" name="role" value={formData.role} id="currency" onChange={handleChange} required>
                                                <option value="">Choose</option>
                                                {roles && roles.map((role,index)=>(
                                                    <option value={role._id}>{role.name}</option>
                                                ))}
                                            </select>
                                        </div>


                                        <div className="col-sm-12 d-flex flex-wrap justify-content-sm-end">
                                            <button type="submit" className="btn btn-primary"  disabled={isLoading}>
                                                {isLoading && <i className="fa fa-spinner fa-spin"></i>}   Update
                                            </button>
                                        </div>
                                    </form>
                                }
                                {!isDataLoading && isNotFound && 
                                    <div className="text-center">
                                    <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                                    <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </Layout>
    </>
  )
}

export default UserEdit