import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout'
import { get, post } from '../../services/ApiService';
import "../custom-style.css";
import Loader from "../../components/Loader";
import Pagination from '../../components/Pagination';
import { Toaster } from 'react-hot-toast';
import Cards from "./Cards";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import DeleteModal from "../../components/DeleteModal";
import { message } from "antd";
import usePermissionCheck from "../../hooks/usePermissionCheck";

const UserList = () => {
    const canEditUser= usePermissionCheck(['User Edit']);
    const canDeleteUser = usePermissionCheck(['User Delete']);
    const [filters, setFilters] = useState({
        name: '',
        limit:'20'
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [users, setUsers] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [delVisible, setDelVisible] = useState(false);
    const [id, setId] = useState('');
    const [isDelete, setIsDelete] = useState(false);


    useEffect(() => {
        fetchData()
    }, [currentPage, filters]);
    
    const fetchData = async () => {

        try {
            setIsLoading(true);
            const Body = {
              page: currentPage,
              ...filters,
            };
            const res = await post(`api/users/list`, Body);
            console.log(res)
            if (res.success) {
              setIsLoading(false);
              setUsers(res.users);
              setTotalPages(res.totalPages);
              setNotFound(false);
            } else {
                setUsers([]);
              setTotalPages(1);
              setIsLoading(false);
              setNotFound(true);
            }
        } catch (error) {
            setUsers([]);
            setTotalPages(1);
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const showDelModal = (id) => {
        setId(id)
        setDelVisible(true);
    };
    const closeDelModal = () => {
        setDelVisible(false);
    };

    const handleDeleteUser = async () => {

        try {
            setIsDelete(true)
            const res = await get(`api/users/delete/${id}`);
            setIsDelete(false)
            if (res.success) {
                message.success('user deleted Successfully');
                closeDelModal();
                fetchData();
            } else {
                message.error('Failed to delete user')
            }
        } catch (error) {
            message.error('Failed to delete user')
        }
    };

    





  return (
    <>
        <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="col-sm-6 mb-8 mb-sm-0">
                    <h2 className="fs-4 mb-0">User List</h2>
                    <Breadcrumbs />
                </div>
                <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                    {usePermissionCheck(['User Create']) && 
                    <Link to={'/users/add'} class="btn btn-primary">
                        <i class="far fa-plus"></i>
                        <span class="d-inline-block ml-1">Create new</span>
                    </Link>
                    }
                </div>
            </div>

            <div className="card mb-4 rounded-4 p-7">
                <div className="card-header bg-transparent px-0 pt-0 pb-7">
                <div className="row align-items-center justify-content-between mb-4">
                    <div className="col-md-4 col-4 col-sm-12 mr-auto mb-md-0 mb-6">
                        <input type="text" placeholder="Search by name" name="name" value={filters.name} onChange={handleFilterChange}  className="form-control bg-input border-0" />
                    </div>
                    <div className="col-md-8 col-8 col-sm-12 mr-auto mb-md-0 mb-6">
                        <div className="row justify-content-end flex-nowrap d-flex">
                            <div className="col-3">
                                <select className="form-select" name="limit" value={filters.limit} onChange={handleFilterChange}>
                                    <option value="10">Show 10</option>
                                    <option value="20">Show 20</option>
                                    <option value="50">Show 50</option>
                                    <option value="100">Show 100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
                <div className="card-body px-0 pt-7 pb-0">
                    {isLoading  ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ):(
                        users.length > 0 && <Cards users={users} showDelModal={showDelModal} canEditUser={canEditUser} canDeleteUser={canDeleteUser} />
                    )}
                    {!isLoading && isNotFound && 
                        <div className="text-center">
                            <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                            <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                        </div>
                    }
                </div>
            </div>
            {totalPages && totalPages > 1 && 
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            }

            <DeleteModal visible={delVisible} onClose={closeDelModal} onConfirm={handleDeleteUser} isDelete={isDelete}/>

        </Layout>
    </>
  )
}

export default UserList