import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import toast, { Toaster } from 'react-hot-toast';
import { loginSuccess } from "../redux/Authentication";
import { useDispatch } from "react-redux";
import { post } from "../services/ApiService";

const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    firstname: isAuthenticated ? isAuthenticated.firstname : "",
    lastname: isAuthenticated ? isAuthenticated.lastname : "",
    email: isAuthenticated ? isAuthenticated.email : "",
    phone: isAuthenticated ? isAuthenticated.phone : "",
  });
  
  const [passwordData, setPasswordData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
  };
  const handlePasswordInput = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        setIsLoading(true);


        const res = await post(`api/auth/profile/update/${isAuthenticated._id}`, formData);
        console.log(res)
        if (res.success) {
            toast.success('Profile updated');
            dispatch(loginSuccess(res.user));
            setIsLoading(false);
        } else {
            toast.error(res.error);
            setIsLoading(false);
        }
    } catch (error) {
        setIsLoading(false);
        toast.error('An error occurred. Please try again.');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (passwordData.new_password !== passwordData.confirm_password) {
        toast.error("New password and confirm password do not match");
        setIsLoading(false);
        return;
      }
      const body = {
        currentPassword:passwordData.current_password,
        newPassword: passwordData.new_password
      }

      const res = await post(`api/auth/password/update/${isAuthenticated._id}`, body);

      if (res.success) {
        toast.success('Password updated successfully');
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <Toaster position="top-center" reverseOrder={false} />
    <Layout>
    <div>
        <div className="row mb-9 align-items-center justify-content-between">
            <div className="col-sm-6 mb-8 mb-sm-0">
            <h2 className="fs-4 mb-0">Profile settings</h2>
            </div>
        </div>
        <div className="card mb-4 rounded-4 p-7">
            <div className="card-body pt-7 pb-0 px-0">
               
                <div className="row mx-n8">
                   
                    <div className="col-12 px-8">
                        <section className="p-xl-8">
                            <h5 className='mb-8'>General Setting</h5>
                            <form className="form-border-1" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="row gx-9">
                                            <div className="col-6 mb-6">
                                            <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="first-name">First
                                                name</label>
                                            <input className="form-control" type="text" placeholder="Type here" name="firstname" 
                                                value={formData.firstname} onChange={handleInput}/>
                                            </div>
                                            <div className="col-6 mb-6">
                                            <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="lastname">Last
                                                name</label>
                                            <input className="form-control" type="text" placeholder="Type here" name="lastname" 
                                                value={formData.lastname} onChange={handleInput}/>
                                            </div>
                                            <div className="col-lg-6 mb-6">
                                            <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="email">Email</label>
                                            <input className="form-control" type="email" placeholder="example@mail.com" name="email"
                                                value={formData.email} onChange={handleInput} />
                                            </div>
                                            <div className="col-lg-6 mb-6">
                                            <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase" htmlFor="phone">Phone</label>
                                            <input className="form-control" type="tel" placeholder="07918 23232" name="phone"
                                                value={formData.phone} onChange={handleInput} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit" disabled={isLoading}>
                                   {isLoading && <i className="fa fa-spinner fa-spin"></i>} Save changes
                                </button>
                            </form>
                            <hr className="my-8" />
                            <form onSubmit={handlePasswordSubmit}>
                                <div className="row">
                                    <div className="col-lg-8">
                                    <h5 className='mb-8'>Password Setting</h5>
                                    <div className="mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase">Current Password</label>
                                        <input className="form-control" type="password" placeholder="Type here" name="current_password" onChange={handlePasswordInput} required />
                                    </div>
                                    <div className="mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase">New Password</label>
                                        <input className="form-control" type="password" placeholder="Type here" name="new_password" onChange={handlePasswordInput} required/>
                                    </div>
                                    <div className="mb-6">
                                        <label className="mb-5 fs-13px ls-1 fw-semibold text-uppercase">Confirm Password</label>
                                        <input className="form-control" type="password" placeholder="Type here" name="confirm_password" onChange={handlePasswordInput} required />
                                    </div>
                                    </div>
                                </div>
                                <button className="btn btn-primary" type="submit" disabled={isLoading}>
                                    {isLoading && <i className="fa fa-spinner fa-spin"></i>} Save changes
                                </button>
                            </form>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>


    </Layout>
    </>
  )
}

export default Profile