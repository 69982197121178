import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../components/Layout";
import { get, post } from "../../../services/ApiService";
import "../../custom-style.css";
import "./style.css";
import Loader from "../../../components/Loader";
import toast, { Toaster } from "react-hot-toast";
import { Link, useParams, useLocation } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { Button, Popconfirm, Flex, Modal, Input, Switch, Collapse} from 'antd';
import { DateFormater, DateTimeFormater,truncateText, getStatus } from "../../../services/Helper";
import NoteForm from "../../../components/NoteForm";
import { useSelector } from "react-redux";
import { selectUserId } from "../../../redux/selector";
import ChatHistory from "./ChatHistory";
import FaqModal from "./FaqModal";
import usePermissionCheck from "../../../hooks/usePermissionCheck";
const { Panel } = Collapse;

const Assessment = () => {
    const canAutomation= usePermissionCheck(['Order Automation']);
    const canEdit= usePermissionCheck(['Order Edit']);
    const canApprove= usePermissionCheck(['Order Approve']);
    const canDecline= usePermissionCheck(['Order Decline']);
    const canCancel= usePermissionCheck(['Order Cancel']);
    const canRefund= usePermissionCheck(['Order Refund']);
    const canViewMsg= usePermissionCheck(['View Message']);
    const canSendMsg= usePermissionCheck(['Send Message']);
    const { id } = useParams();
    const userId = useSelector(selectUserId);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const notificationId = queryParams.get('notification');
    const [isLoading, setIsLoading] = useState(true);
    const [isChatLoading, setIsChatLoading] = useState(true);
    const [isSending, setIsSending] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isCancelRefund, setIsCancelRefund] = useState(false);
    const [isNotFound, setNotFound] = useState(false); 
    const [isNoMessage, setNoMessage] = useState(false); 
    const [NoMessageText, setNoMessageText] = useState(''); 
    const [assessment, setAssessment] = useState(''); 
    const [mailLogs, setMailLogs] = useState([]); 
    const [productIds, setProductIds] = useState([]); 
    const [approvedPro, setApprovedPro] = useState([]); 
    const [cancelledPro, setCancelledPro] = useState([]); 
    const [cancelReasons, setCancelReasons] = useState({});
    const [isAllApproved, setIsAllApproved] = useState(false);
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const textareaRef = useRef(null);
    const [order, setOrder] = useState('');
    const [groupedOrders, setGroupedOrders] = useState({});
    const allProductsProcessed = approvedPro.length + cancelledPro.length === productIds.length;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [cancelReasonInput, setCancelReasonInput] = useState('');
    const [cancelProductId, setCancelProductId] = useState(null);
    const [notes, setNotes] = useState([]);
    const [reminder, setReminder] = useState(order?.reminder || 0);
    const [reminderLoading, setReminderLoading] = useState(false);
    const [faqVisible, setFaqVisible] = useState(false);
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        fetchData();
        getMessages(); 
   }, [id, notificationId]);
   
   useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; 
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message]);



    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await get(`api/orders/p-med/assessment/${id}${notificationId ? `?notification=${notificationId}` : ''}`);
            setIsLoading(false);
            console.log(res.productAssessment)
            if (res.success) {
                setOrder(res.order);
                setReminder(res.order?.reminder);
                setAssessment(res.assessment);
                setMailLogs(res.mailLogs);
                setNotes(res.notes)
                setFaqs(res.faqs)
                if(res.assessment && res.assessment.products){
                    const ids = res.assessment.products.map(product => product.productId);
                    setProductIds(ids);
                }

                const orders = res.orders;
                if(orders.length > 0 ){
                    const grouped = groupOrdersByMonth(orders);
                    setGroupedOrders(grouped);
                }

            } else {
                setOrder('');
                setAssessment('');
                setNotFound(true);
            }
        } catch (error) {
            
            setIsLoading(false);
            setOrder('');
            setAssessment('');
            setNotFound(true);
        } 
    };

    const getMessages = async () => {
        try {
            setIsChatLoading(true);
            setNoMessage(false);
            const res = await get(`api/messages/${id}`);
            setIsChatLoading(false);
            if (res.success) {
                console.log(res.data)
                setMessages(res.data);
            } else {
                setNoMessageText(res.error);
                setNoMessage(true);
            }
        } catch (error) {
            setIsChatLoading(false);
            setNoMessage(true);
            setNoMessageText(error);
            console.log(error);

        } 
    };
    const handleSubmit = async (e) => {
        try {
            const Body = {
                approved:approvedPro,
                canceled: cancelledPro,
                cancelReasons: cancelReasons,
                assessmentId:assessment?._id,
                orderId:id,
                userId
            };
            setIsSubmit(true);
            const res = await post(`api/orders/p-med/assessment/handling`, Body);
            setIsSubmit(false);
            if (res.success) {
                toast.success(res.message);
                setAssessment(res.assessment);
                setApprovedPro([])
                setCancelledPro([])
            } else {
                toast.error(`Failed to update assessment`);
            }
        } catch (error) {
            setIsSubmit(false);
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleCancelRefund = async () => {
        try {

            const Body = {
                canceled: cancelledPro,
                cancelReasons: cancelReasons,
                assessmentId:assessment?._id,
                orderId:id,
                userId
            };
            setIsCancelRefund(true);
            const res = await post("api/orders/p-med/cancel-refund", Body);
            setIsCancelRefund(false);
            if (res.success) {
                toast.success(res.message);
                setAssessment(res.assessment);
                setApprovedPro([])
                setCancelledPro([])
            } else {
                toast.error(`Failed to update assessment`);
            }
        } catch (error) {
            setIsSubmit(false);
            toast.error('An error occurred. Please try again.');
        }
    };
    
    const handleSendMessage = async () => {

        if(!message && message === ''){
            toast.error('Enter your message first');
            return;
        }
        const Body = {
            message:message,
            orderId:id
        };
        setIsSending(true);
        const res = await post(`api/messages/send`, Body);
        setIsSending(false);
        if (res.success) {
            toast.success('Message sent');
            const newMessage = {
                body: message,
                creationDate: new Date().toISOString(),
                messageID: "",
                sender: "pillpharm",
                subject: ""
            };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            setMessage('');
        } 

    };

    const groupOrdersByMonth = (orders) => {
        const groupedOrders = {};
        orders.forEach(order => {
            const date = new Date(order.createdAt);
            const month = date.toLocaleString('default', { month: 'long' });
            const year = date.getFullYear();
            const monthYear = `${month} ${year}`;
        
            if (!groupedOrders[monthYear]) {
            groupedOrders[monthYear] = [];
            }
            groupedOrders[monthYear].push(order);
        });
        
        return groupedOrders;
    };

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        setIsAllApproved(isChecked);

        if (isChecked) {
            // Add all product IDs to approvedPro
            setApprovedPro(productIds);
        } else {
            // Remove all product IDs from approvedPro
            setApprovedPro([]);
        }
    };



    const confirmApprove = (id) => {
        setApprovedPro([...approvedPro, id]);
    };

    const cancel = (e) => {};

    const showCancelModal = (id) => {
        setCancelProductId(id);
        setIsModalVisible(true);
    };
    
    const handleCancelOk = () => {
        if (cancelProductId && cancelReasonInput.trim()) {
            setCancelledPro([...cancelledPro, cancelProductId]);
            setCancelReasons({
                ...cancelReasons,
                [cancelProductId]: cancelReasonInput.trim()
            });
            setCancelReasonInput('');
            setCancelProductId(null);
            setIsModalVisible(false);
        } else {
            toast.error('Please provide a reason for cancellation.');
        }
    };

    const handleCancelCancel = () => {
        setCancelReasonInput('');
        setCancelProductId(null);
        setIsModalVisible(false);
    };

    const handleReminderChange = async (orderId,reminder) => {
        try{
  
            const Body = {
                id:orderId,
                reminder
            };
            setReminderLoading(true);
            const res = await post(`api/orders/reminder/update`, Body);

            setReminderLoading(false);
            if (res.success) {
                setReminder(res.order.reminder);
                toast.success('Reminder updated successfully.');
            } else{
                toast.error('Failed to update reminder status.');
            }
        }catch(error){
            toast.error('Error updating reminder status.');
        }

    };

    const showFaqModal = () => {
        setFaqVisible(true);
      };
    
      const handleFaqCancel = () => {
        setFaqVisible(false);
    };
    const handleCreateFaq = async (values) => {
        try {
            console.log(values);
            const res= await post('api/external-log/faqs/add', values);
            setFaqs([...faqs, res.faq]);
            setFaqVisible(false);
            toast.success('FAQ added successfully!');

        } catch (error) {
            toast.error('Failed to add FAQ');

        }
    };

    const orderCancelRefund = async (type) => {
        try {
            const Body = {
                orderId:id,
                type
            };
            setIsCancelRefund(true);
            const res = await post("api/orders/status/cancel", Body);
            setIsCancelRefund(false);
            if (res.success) {
                toast.success(res.message);
            } else {
                toast.error(`Failed to update assessment`);
            }
        } catch (error) {
            setIsSubmit(false);
            toast.error('An error occurred. Please try again.');
        }
    };
    

  return (

    <>
      <Layout>
        <Toaster position="top-center" reverseOrder={false} />
        <div>
          <div className="row mb-9 align-items-center justify-content-between">
            <div className="col-sm-6 mb-8 mb-sm-0">
                    <h2 className="fs-4 mb-0">Order Detail</h2>
                    <Breadcrumbs/>
            </div>
          </div>
          {isLoading &&
            <div className='text-center p-4'>
                <Loader />
            </div>
          }
          {!isLoading && !isNotFound &&
          <>
          <div className="card mb-4" style={{background:'transparent',border:'none'}}>
                <div className="card-body">
                    <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between">
                    <div className={`step ${order?.status === 'PENDING' || order?.status === 'ASSESSMENT_REQUIRED' || order?.status === 'NOT_STARTED' || order?.status === 'PROCESSED' || order?.status === 'SHIPPED' ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                            <div className="step-icon"><i className="fa fa-shopping-cart" /></div>
                        </div>
                        <h4 className="step-title">Receiving Order</h4>
                    </div>
                    <div className={`step ${order?.status === 'PROCESSED' || order?.status === 'SHIPPED' ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                        <div className="step-icon"><i className="fa fa-repeat" /></div>
                        </div>
                        <h4 className="step-title">Processing Order</h4>
                    </div>
                    <div className={`step ${order?.status === 'SHIPPED' ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                        <div className="step-icon"><i className="fa fa-gift" /></div>
                        </div>
                        <h4 className="step-title">Product Dispatched</h4>
                    </div>
                    <div className={`step ${order?.status === 'SHIPPED' ? 'completed' : ''}`}>
                        <div className="step-icon-wrap">
                        <div className="step-icon"><i className="fa fa-shipping-fast" /></div>
                        </div>
                        <h4 className="step-title">On Delivery</h4>
                    </div>
                    <div className="step">
                        <div className="step-icon-wrap">
                        <div className="step-icon"><i className="fa fa-thumbs-up" /></div>
                        </div>
                        <h4 className="step-title">Product Delivered</h4>
                    </div>
                    </div>
                </div>
          </div>
          <div className="card rounded-4" >
            <>
                <header className="card-header bg-transparent p-7">
                    <div className="row align-items-center">
                        <div className="col-md-6 mb-lg-0 mb-6">
                        <span className="d-inline-block">
                            <i className="far fa-calendar me-3" />
                            {DateFormater(order?.createdAt)}
                        </span>
                        <br />
                        <small className="text-muted">{order?.orderId}</small>
                        {order.trackingNumber && 
                            <>
                            <br />
                            <small className="text-primary">
                                Tracking: <Link to={`https://www.royalmail.com/track-your-item#/tracking-results/${order?.trackingNumber}`}>{order?.trackingNumber}</Link> 
                            </small>
                            </>
                        }
                        <br/>
                        <span
                            className={`badge rounded-lg rounded-pill alert py-3 px-4 mb-0 border-0 text-capitalize fs-12 ${getStatus(order.status).class}`}
                            >
                            {getStatus(order?.status).text}
                        </span>

                        </div>
                        <div className="col-md-6 ml-auto d-flex justify-content-md-end flex-wrap">

                        {order?.type === "P-Med" && assessment &&
                            <>
                            {order.anomalyVisible &&
                                <>
                                    <i class="fa fa-circle text-danger-glow-red blink"></i>
                                    <a href={`/assessment/tracker/${assessment?._id}`} target="_blank" rel="noreferrer" >
                                        <img src="/assets/images/expense.png" alt="Assessment Tracker" style={{width:'30px',marginTop:'5px'}} />
                                    </a>
                                </>
                            }

                            <Link to={`/questionnaire/${assessment?._id}`} className="btn btn-primary print ms-5 my-3 btn-sm" href="#">
                                See Assessment
                            </Link>
                            <Link to={`/ebay/question/demo/${assessment?._id}`} className="btn btn-success print ms-5 my-3 btn-sm" href="#">
                                eBay Questionaire
                            </Link>
                            </>
                        } 

                        </div>
                    </div>
                </header>
                <div className="card-body p-7">
                    <div className="row mb-8 mt-4 order-info-wrap">
                        <div className="col-md-4 mb-md-0 mb-7">
                            <div className="d-flex flex-nowrap">
                                <div className="icon-wrap">
                                    <span className="rounded-circle px-6 py-5 bg-green-light me-6 text-green d-inline-block">
                                        <i className="fas fa-user px-1" />
                                    </span>
                                </div>
                                <div className="media-body">
                                    <h6 className="mb-4">Customer</h6>
                                    <small className="m-0 p-0">
                                        {order?.buyerInfo?.fullname} ({order?.buyerInfo?.username})
                                    </small><br/>
                                    <small className="m-0 p-0">{truncateText(order?.buyerInfo?.email ,25) }</small><br/>
                                    <small className="m-0 p-0">{order?.buyerInfo?.phone }</small>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex flex-nowrap">
                                <div className="icon-wrap">
                                    <span className="rounded-circle p-5 bg-green-light me-6 text-green d-inline-block">
                                        <i className="fas fa-truck px-2" />
                                    </span>
                                </div>
                                <div className="media-body">
                                    {canEdit && 
                                        <h6 className="mb-4">Delivery Address <Link to={`/orders/edit/${order?._id}`} className="text-primary fs-16"><i className="fa fa-pen"></i></Link></h6>
                                    }
                                    <small> {order?.shippingInfo?.addressLine1} {order?.shippingInfo?.addressLine2 ? `, ${order?.shippingInfo?.addressLine2}`:''}</small><br />
                                    <small className="text-capitalize">{order?.shippingInfo?.city},</small>
                                    <small className="text-uppercase"> { order.shippingInfo?.zip},</small>
                                    <small className="text-uppercase"> { order?.shippingInfo?.country}</small>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="media-body">
                            <h6 className="mb-4">Assessment logs </h6>
                            {order.type === 'P-Med' && canAutomation &&

                                <p>
                                    {/* Use the Ant Design Switch component */}
                                    <Switch
                                    loading={reminderLoading}
                                    checked={reminder === 1} // Set checked state based on reminder value
                                    onChange={(checked) => handleReminderChange(order?._id, checked ? 1 : 0)} // Pass orderId and new reminder value
                                    className="rounded-0 ms-0 custom-switch"
                                    size="small"
                                    />
                                    {' '}<span className="automation-title">P-Med order automation</span>
                                </p>
                            }

 
                            {order.type === "P-Med" && 
                                <div className="col-12 mt-2">
                                    {mailLogs && mailLogs.length > 0 && 
                                        <>
                                        {mailLogs.map((log, index) => {
                                        if (index + 1 < 3) {
                                            return (
                                                <>
                                                <small className="m-0 p-0 text-danger" key={index}>
                                                    {index + 1} / 2 Assessment Sent - {log.mailSentAt ? DateTimeFormater(log.mailSentAt) : ''}
                                                </small> <br/>
                                                </>
                                            );
                                        } else {
                                            return (
                                                <>
                                                <small className="m-0 p-0 text-danger" key={index}>
                                                    eBay Questionnaire Sent - {log.mailSentAt ? DateTimeFormater(log.mailSentAt) : ''}
                                                </small><br/>
                                                </>

                                            );
                                        }
                                        })}
                                        </>
                                    }
                               
                                    {assessment && assessment?.status === "COMPLETE" && 
                                        <p className="m-0 p-0 text-danger" style={{fontSize:'12px'}}>
                                            Assessment Completed at {assessment.completedAt ? DateTimeFormater(assessment.completedAt): ''}
                                        </p>
                                    }
                                    {assessment.isReview && assessment.reviewAt !== '' && 
                                            <>
                                            <small className="m-0 p-0 text-danger">
                                            Assessment reviewed
                                            {assessment.reviewBy ? ` by ${assessment.reviewBy}` : ''}
                                            {assessment.reviewAt ? ` at ${DateTimeFormater(assessment.reviewAt)}` : ''}
                                            </small><br/>
                                            </>
                                    }


                                </div>
                            }
                        </div>
                        </div>
                    </div>
                </div>
            </>
            
          </div>

            <div className="row">
                <div className="col-xl-5 col-lg-5 col-sm-12 col-5">
                    <div className="card mb-7 rounded-4 p-7 mt-4">
                        <div className="card-body p-0">
                            <h5 className="card-title fs-6 mb-6">
                            Orders History
                            </h5>
                            {Object.keys(groupedOrders).map(monthYear => (
                                <>
                                <h6 className="text-primary">{monthYear}</h6>
                                <ul key={monthYear} className="verti-timeline list-unstyled fs-13px mx-4">
                                    {groupedOrders[monthYear].map(order => (
                                        <>
                                        <li  className="event-list d-flex align-items-center position-relative">
                                            <div className="event-timeline-dot"></div>
                                            <div className="d-flex flex-row align-items-center">
                                            <div className="me-6">
                                                <h6 className="fs-13px mb-0">
                                                    <span className="d-inline-block me-6 w-50px" style={{fontSize:'10px'}}>
                                                        {DateFormater(order?.createdAt)}
                                                    </span>
                                                    {/* <i className="fa fa-arrow-right"></i> */}
                                                </h6>
                                            </div>
                                            <p className="mb-0 fs-13px">
                                                <span> <Link to={`/orders/${order?._id}`}>{order.orderId}</Link> </span>
                                                <span
                                                    className={`badge rounded-lg rounded-pill alert py-3 px-4 mb-0 border-0 text-capitalize fs-12 ${getStatus(order.status).class}`}
                                                    >
                                                    {getStatus(order?.status).text}
                                                </span><br/>
                                                {order?.products.map((pro,index)=>(
                                                    <>
                                                        <span title={pro?.title}> {pro?.title} <span className="text-danger">x{pro?.quantity}</span></span><br/>
                                                    </>
                                                ))}
                                            </p>
                                            </div>
                                        </li>
                                        
                                        </>
                                        
                                    ))}
                                </ul>
                                </>
                            ))}
                        </div>
                    </div>
                    {assessment  && assessment.products.length > 0 && 
                        <>
                        {assessment && order.status !== "PROCESSED" && assessment.products.length > 1 &&
                            <h6>
                                <div className="form-check">
                                        <input className="form-check-input rounded-0 ms-0" type="checkbox"  style={{borderColor:'#ee5454'}} checked={isAllApproved} onChange={handleCheckboxChange}/>
                                        <label className="form-check-label m-3" style={{color:'#ee5454',fontWeight:200,fontFamily:'ui-monospace'}}> Do you want to approve whole order ?</label>
                                </div>
                            </h6>
                        }
                        {assessment.products.map((proAssess, index) => (
                            
                            <div className="accordion accordion-flush" id="accordionFlushExample" key={proAssess.productId}>
                                <div className="accordion-item mb-4">
                                    <h2 className="accordion-header" id={`flush-heading-${index}`}>
                                        <button 
                                            className={`accordion-button ${assessment.status === "PENDING" ? "collapsed" : ""}`} 
                                            type="button" 
                                            data-bs-toggle="collapse" 
                                            data-bs-target={`#flush-collapse-${index}`} 
                                            aria-expanded={assessment.status !== "PENDING"} 
                                            aria-controls={`flush-collapse-${index}`}
                                        >
                                            {proAssess.productName} 
                                        </button>
                                    </h2>
                                    <div 
                                        id={`flush-collapse-${index}`} 
                                        className={`accordion-collapse collapse ${assessment.status === "PENDING" ? "" : "show"}`} 
                                        aria-labelledby={`flush-heading-${index}`} 
                                        data-bs-parent="#accordionFlushExample"
                                    >
                                        <div className="accordion-body">
                                            {proAssess.questions && proAssess.questions !== '' && 
                                                proAssess.questions.map((res, key) => (
                                                    <div key={res.id}>
                                                        <div className="col-lg-12">
                                                            <div className="d-flex align-items-center gap-3">
                                                                <div className="w-100">
                                                                    <label className="pb-1 text-black m-3" style={{ fontSize: '12px' }}>
                                                                        {res.title}
                                                                        {res.description && 
                                                                            <>
                                                                                <br />
                                                                                <span className="question-desc" dangerouslySetInnerHTML={{ __html: res.description }} />
                                                                            </>
                                                                        } 
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            {res.response && 
                                                                <div className="d-flex align-items-center gap-2 justify-content-end">
                                                                    <div 
                                                                    className={`right-block-box text-white fs-14 ${
                                                                        (res.type !== 'textarea' && 
                                                                        (!res.correctAnswer || res.correctAnswer.trim().toLowerCase() === res.response.trim().toLowerCase())) 
                                                                        ? 'bg-success' 
                                                                        : ''
                                                                    }`}
                                                                    >
                                                                        {res.response}
                                                                    </div>

                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                ))
                                            }
                                          
                                            {assessment && 
                                                !assessment.isReview  &&
                                                 (order.status === "PENDING" || order.status === "ASSESSMENT_REQUIRED") &&
                                                  !approvedPro.includes(proAssess.productId) && !cancelledPro.includes(proAssess.productId) &&
                                                <>    
                                                    <hr />
                                                    <div className="col-12 text-end mt-2">
                                                        <Flex wrap gap="small">
                                                            {canDecline &&
                                                            <button 
                                                                className="btn btn-sm btn-danger"
                                                                disabled={approvedPro.includes(proAssess.productId) || cancelledPro.includes(proAssess.productId)}
                                                                onClick={() => showCancelModal(proAssess.productId)}
                                                            >
                                                                {cancelledPro.includes(proAssess.productId) ? 'X Declined' : 'X Decline'}
                                                            </button>
                                                            }
                                                            {canApprove &&
                                                            <Popconfirm
                                                                title="Approve the product"
                                                                description="Do you confirm ?"
                                                                onConfirm={() => confirmApprove(proAssess.productId)}
                                                                onCancel={cancel}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button type="primary">✓ Approve</Button>
                                                            </Popconfirm>
                                                            }
                                                        </Flex>
                                                    </div>
                                                </>
                                            }
                                            {approvedPro.includes(proAssess.productId) && <span className="text-primary">Product Approved</span>}
                                            {cancelledPro.includes(proAssess.productId) && <span className="text-danger">Product Cancelled</span>}
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                        ))}

                        {assessment && (order.status === "PENDING" || order.status === "ASSESSMENT_REQUIRED") && allProductsProcessed && (
                            <>
                            <div className="text-center mt-4">
                                <Button type="primary" onClick={handleSubmit} disabled={isSubmit}>
                                    {isSubmit && <i className="fa fa-spinner fa-spin"></i>}  Submit Approved/Cancelled Products
                                </Button>
                            </div>
                            

                            {order.platform === 'Ebay' && cancelledPro.length > 0 && approvedPro.length === 0 && canRefund  &&
                                <div className="text-center mt-4">
                                    <Button danger onClick={handleCancelRefund}  disabled={isCancelRefund}>
                                        {isCancelRefund && <i className="fa fa-spinner fa-spin"></i>} Order Cancel & Refund
                                    </Button>
                                </div>
                            }

                            </>
                        )}


                        {assessment.products.map((product, index) => (
                            <>
                            {product.isCancel && 

                                <div className="card note-card" style={{background:'antiquewhite'}}>
                                    <div className="card-body">
                                        <p className="m-0 p-0 note-title">Product Cancelled</p>
                                        <p className="m-0 p-0 note-date">{assessment?.reviewAt ? DateTimeFormater(assessment?.reviewAt):''} </p>
                                        <p className=" m-0 p-0 note-product">{product.productName} </p>
                                        <p className=" m-0 p-0 note-body">{product.reason} </p>

                                    </div>
                                </div>

                            }

                            </>
                        ))}
                        </>
                    }
                    {order && order.status === 'SEND_TO_RM' && 
                        <div className="d-flex">
                            {canCancel && 
                                <div className="">
                                    <div className="mt-4">
                                        <Button danger onClick={() => orderCancelRefund('cancel')} disabled={isCancelRefund}>
                                            {isCancelRefund && <i className="fa fa-spinner fa-spin"></i>} Order Cancel 
                                        </Button>
                                    </div>
                                </div>
                            }
                            {canRefund && 
                                <div className="">
                                    <div className="mt-4" style={{marginLeft:'20px'}}>
                                        <Button danger type="dashed" onClick={() => orderCancelRefund('refund')} disabled={isCancelRefund}>
                                            {isCancelRefund && <i className="fa fa-spinner fa-spin"></i>} Cancel & Refund
                                        </Button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {notes && notes.length > 0 && 
                        notes.map((note,index)=>(
                            <div className="card note-card mt-4" key={index}>
                                <div className="card-body">
                                    <p className="m-0 p-0 note-title">{truncateText(note.title, 45)}</p>
                                    <p className="m-0 p-0 note-date">{DateTimeFormater(note.createdAt)} </p>
                                    <p className=" m-0 p-0 note-body">{note.description} </p>
                                </div>
                            </div>
                        ))
                    }
                    
                    <div className="card mt-4" style={{background:'transparent'}}>
                        <div className="card-body">
                        <NoteForm id={id} setNotes={setNotes} notes={notes} />
                        </div>
                    </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-sm-12 col-7">
                    <div>
                    <div className="tab-content mb-3 mt-4" id="pills-tabContent">
                        {order.platform === 'Ebay' &&
                        <div className={`tab-pane fade  ${order.platform === 'Ebay' ? 'show active':''}`} id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" tabIndex={0}>
                            <div className="card rounded-3">
                                <div className="card-header bg-blue py-3">
                                    <img src="/assets/images/chat/msg.png" className="img-fluid" width="30px" alt="" />
                                    <h6 className="text-white mt-2 mb-1 fw-semibold">
                                        eBay Message
                                    </h6>

                                </div>
                                <div className="card-body ">
                                {isChatLoading &&
                                    <div className='col-12 text-center' >
                                        <Loader />
                                    </div>
                                }
                                {canViewMsg &&
                                    <ChatHistory messages={messages} isChatLoading={isChatLoading} isNoMessage={isNoMessage} NoMessageText={NoMessageText} />
                                }

                                </div>
                                <div className="card-footer border-0 bg-transparent">
                                    {canSendMsg && 
                                        <>
                                       <textarea
                                        ref={textareaRef}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        className="form-control"
                                        rows={5}
                                        style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            resize: "none",
                                        }}
                                        placeholder="Type your message here..."
                                        />
                                        <div className="col-12 text-end">
                                            <button type="button" className="btn btn-primary btn-sm m-4" onClick={handleSendMessage} disabled={isSending}>
                                                Send {isSending ? <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>:<i class="fa fa-paper-plane" aria-hidden="true"></i>} 
                                            </button>
                                        </div>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                        }
                        <div className={`tab-pane fade  ${order.platform === 'GroupOn' ? 'show active':''}`}  id="pills-faq" role="tabpanel" aria-labelledby="pills-faq-tab" tabIndex={0}>
                            <div className="card rounded-3">
                                <div className="card-header bg-blue py-3">
                                <img src="/assets/images/chat/faq-w.png" className="img-fluid" width="30px" alt=""/>
                                <h6 className="text-white mt-2 mb-1 fw-semibold">
                                    eBay Faqs
                                </h6>
                                <p className="text-white fs-14">
                                   Define your FAQ's here
                                </p>
                                </div>
                                <div className="card-body h-fix">
                                    <div className="col-12 text-end mb-4">
                                    <Button type="primary" onClick={showFaqModal} >
                                        Add FAQ
                                    </Button>
                                    <FaqModal
                                        visible={faqVisible}
                                        onCreate={handleCreateFaq}
                                        onCancel={handleFaqCancel}

                                    />
                                    </div>
                                    {faqs && faqs.length > 0 ? (
                                        <Collapse>
                                            {faqs.map((faq) => (
                                            <Panel header={faq.title} key={faq._id}>
                                               <div dangerouslySetInnerHTML={{ __html: faq.description }} />
                                            </Panel>
                                            ))}
                                        </Collapse>
                                    ):(
                                        <p className="text-center text-danger">No data found!</p>
                                    )}


                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <ul className="nav nav-pills cust-pill flex-nowrap" id="pills-tab" role="tablist">
                        {order.platform === 'Ebay' &&
                            <li className="nav-item w-100" role="presentation">
                                <button className="nav-link text-dark rounded-0 active d-flex align-items-center justify-content-center w-100 gap-2" id="pills-chat-tab" data-bs-toggle="pill" data-bs-target="#pills-chat" type="button" role="tab" aria-controls="pills-chat" aria-selected="true">
                                    <img src="/assets/images/chat/msg-1.png" alt="" className="img-fluid" width="20px" />
                                    <span>CHAT</span>
                                </button>
                            </li>
                        }
                        <li className="nav-item w-100" role="presentation">
                        <button className="nav-link text-dark rounded-0 d-flex align-items-center justify-content-center w-100 gap-2" id="pills-faq-tab" data-bs-toggle="pill" data-bs-target="#pills-faq" type="button" role="tab" aria-controls="pills-faq" aria-selected="false">
                            <img src="/assets/images/chat/faq.png" alt="" className="img-fluid" width="20px" />
                            <span>FAQ</span>
                        </button>
                        </li>
                    </ul>
                    </div>

                </div>
            </div>
            </>
          }
          {!isLoading && isNotFound && 
                <div className="text-center">
                    <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                    <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                </div>
            }

        </div>
        <Modal 
            title="Enter Cancellation Reason" 
            visible={isModalVisible} 
            onOk={handleCancelOk} 
            onCancel={handleCancelCancel}
            okText="Submit"
            cancelText="Cancel"
        >
            <Input.TextArea 
                rows={4} 
                value={cancelReasonInput}
                onChange={(e) => setCancelReasonInput(e.target.value)}
                placeholder="Enter the reason for cancellation"
            />
        </Modal>
      </Layout>
    </>
  );
};

export default Assessment;
