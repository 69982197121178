import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout';
import "../custom-style.css";
import 'react-quill/dist/quill.snow.css';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { Modal, Input, Button, Form, message,Select } from 'antd';
import { get, post } from '../../services/ApiService';
import usePermissionCheck from "../../hooks/usePermissionCheck";
const {Option } = Select;
const Roles = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [roles, setRoles] = useState([]); 
    const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
    const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
    const [roleForm] = Form.useForm();
    const [permissionForm] = Form.useForm();


    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            setIsLoading(true);
            const res = await get(`api/roles/list`);
            console.log(res);
            if (res.success) {
                setIsLoading(false);
                setRoles(res.roles);
                setNotFound(false);
            } else {
                setRoles([]);
                setIsLoading(false);
                setNotFound(true);
            }
        } catch (error) {
            setRoles([]);
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
    };

    const handleAddRoleClick = () => setIsRoleModalOpen(true);
    const handleAddPermissionClick = () => setIsPermissionModalOpen(true);
    
    const handleRoleModalClose = () => {
        setIsRoleModalOpen(false);
        roleForm.resetFields(); // Clear role form fields when modal is closed
    };
    
    const handlePermissionModalClose = () => {
        setIsPermissionModalOpen(false);
        permissionForm.resetFields(); // Clear permission form fields when modal is closed
    };

    const handleRoleSubmit = async (values) => {
        try {
            const body = {
                name:values.roleName
            }
            const res = await post(`api/roles/add`, body);
            if (res.success) {
                message.success('Role added successfully!');
                fetchData(); 
                handleRoleModalClose();
            } else {
                message.error(res.error);
            }

        } catch (error) {
            console.error("Error adding role:", error);
            message.error('Failed to add role.');
        }
    };

    const handlePermissionSubmit = async (values) => {
        try {
            const body = {
                name:values.permissionName,
                section:values.section
            }
            const res = await post(`api/roles/permission/add`, body);
            if (res.success) {
                message.success('Permission added successfully!'); 
                // handlePermissionModalClose();
            } else {
                message.error('Failed to add Permission!');
            }

        } catch (error) {
            console.error("Error adding Permission:", error);
            message.error('Failed to add Permission.');
        }
    };

    return (
        <>
        <Layout>
            <div>
                <div className="row mb-9 align-items-center">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-6 mb-8 mb-sm-0">
                                <h2 className="fs-4 mb-0">Roles</h2>
                                <Breadcrumbs/>
                            </div>
                            <div className="col-sm-6">
                                <div className="text-end mt-4">
                                    {usePermissionCheck(['Role Create']) &&
                                        <Button type="primary" onClick={handleAddRoleClick} className="m-2">
                                            Add Role
                                        </Button>
                                    }
                                    {usePermissionCheck(['Permission Create']) && 
                                        <Button type="primary" onClick={handleAddPermissionClick} className="m-2">
                                            Add Permission
                                        </Button>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row" style={{marginTop:'50px'}}>
                            {roles && roles.length > 0 && 
                                <>
                                {roles.map((role,index) =>(
                                <div className="col-lg-3 col-md-4 col-sm-6">
                                    <div className="card note-card">
                                        <div className="card-body">
                                            <div className="row">
                                                <h6 className="note-title">{role.name}</h6>
                                                <div className="row">
                                                    {/* <div className="col-2">
                                                        <Link className="note-edit-btn"><i className="fa fa-trash note-edit-icon"></i></Link>
                                                    </div> */}
                                                    <div className="col-2">
                                                        <Link className="note-edit-btn" to={`/roles/${role._id}/permissions`}><i class="fa fa-tasks note-edit-icon" aria-hidden="true"></i></Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                ))}

                                </>
                            }

                            {/* Additional role cards can be added here */}
                        </div>
                    </div>
                </div>
            </div>

            {/* Role Modal with Form */}
            <Modal
                title="Add New Role"
                visible={isRoleModalOpen}
                onCancel={handleRoleModalClose}
                footer={null}
            >
                <Form form={roleForm} onFinish={handleRoleSubmit}>
                    <Form.Item
                        name="roleName"
                        rules={[{ required: true, message: 'Please enter the role name' }]}
                    >
                        <Input placeholder="Role Name" className="form-control" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Save Role
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Permission Modal with Form */}
            <Modal
                title="Add New Permission"
                visible={isPermissionModalOpen}
                onCancel={handlePermissionModalClose}
                footer={null}
            >
                <Form form={permissionForm} onFinish={handlePermissionSubmit}>
                    <Form.Item
                        name="permissionName"
                        rules={[{ required: true, message: 'Please enter the permission name' }]}
                    >
                        <Input placeholder="Permission Name" className="form-control" />
                    </Form.Item>
                    <Form.Item
                        name="section"
                        rules={[{ required: true, message: 'Please select a section' }]}
                    >
                        <Select placeholder="Select Section" className="form-control">
                            <Select.Option value="General Section">General Section</Select.Option>
                            <Select.Option value="Role Section">Role Section</Select.Option>
                            <Select.Option value="User Section">User Section</Select.Option>
                            <Select.Option value="Question Section">Question Section</Select.Option>
                            <Select.Option value="Product Section">Product Section</Select.Option>
                            <Select.Option value="Order Section">Order Section</Select.Option>
                            <Select.Option value="Meeting Section">Meeting Section</Select.Option>
                            <Select.Option value="Setting Section">Setting Section</Select.Option>
                            <Select.Option value="External Log Section">External Log Section</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Save Permission
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </Layout>
        </>
    );
};

export default Roles;
