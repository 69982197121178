// ProtectedRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from './selector';
import { usePermissions } from './PermissionsContext';

const ProtectedRoute = ({ component: Component, requiredPermissions, ...rest }) => {
    const user = useSelector(selectUser);
    const authed = localStorage.getItem('token');
    const storedToken = localStorage.getItem('token-expiration');
    const currentTime = Math.floor(Date.now() / 1000);
    const { rolePermissions } = usePermissions();

    const hasPermission = requiredPermissions
        ? requiredPermissions.every(permission => rolePermissions.includes(permission))
        : true;

    if (!authed || storedToken < currentTime) {
        return <Navigate to="/login" replace />;
    }

    if (!hasPermission) {
        return <Navigate to="/unauthorized" replace />;
    }

    return <Component {...rest} />;
};

export default ProtectedRoute;
