import React, { useState } from 'react';
import { Modal, Select, Input, DatePicker, message } from 'antd';
import moment from 'moment';

const { Option } = Select;

const EventModal = ({ isModalVisible }) => {
  const [shiftDate, setShiftDate] = useState(null);

  // Generate an array of time slots in 24-hour format
  const timeSlots = Array.from({ length: 24 * 2 }, (_, index) => {
    const hours = Math.floor(index / 2);
    const minutes = index % 2 === 0 ? '00' : '30';
    return `${hours.toString().padStart(2, '0')}:${minutes}`;
  });

  const onSave = () => {
    message.success('Event saved successfully!');
  };

  return (
    <Modal
      title="Event Calendar"
      open={isModalVisible}
      onOk={onSave}
      onCancel={() => message.info('Modal closed')}
      okText="Save"
      cancelText="Cancel"
    >
      <div className="shift-form">
        <div className="col-12 mt-4">
            <input type='text' className='form-control' placeholder='Event Name' />
        </div>
        <div className='row mt-4'>
            <div className="form-check">
                    <input className="form-check-input rounded-0 ms-0" type="checkbox"  style={{borderColor:'black'}}/>
                    <label className="form-check-label m-2">Today</label>
            </div>
        </div>
        <div className="row mt-4">
          <div className="col-5">
            <select className='form-control' placeholder="From Time">
                <option key="Monday" value={`Monday`}>{`Monday`}</option>
                <option key="Tuesday" value={`Tuesday`}>{`Tuesday`}</option>
                <option key="Wednesday" value={`Wednesday`}>{`Wednesday`}</option>
                <option key="Thursday" value={`Thursday`}>{`Thursday`}</option>
                <option key="Friday" value={`Friday`}>{`Friday`}</option>
                <option key="Saturday" value={`Saturday`}>{`Saturday`}</option>
                <option key="Sunday" value={`Sunday`}>{`Sunday`}</option>
            </select>
          </div>
          <div className="col-1">
            <span style={{fontSize:'30px'}}>-</span>
          </div>
          <div className="col-5">
            <select className='form-control' placeholder="To Time">
                <option key="Monday" value={`Monday`}>{`Monday`}</option>
                    <option key="Tuesday" value={`Tuesday`}>{`Tuesday`}</option>
                    <option key="Wednesday" value={`Wednesday`}>{`Wednesday`}</option>
                    <option key="Thursday" value={`Thursday`}>{`Thursday`}</option>
                    <option key="Friday" value={`Friday`}>{`Friday`}</option>
                    <option key="Saturday" value={`Saturday`}>{`Saturday`}</option>
                    <option key="Sunday" value={`Sunday`}>{`Sunday`}</option>
                </select>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-5">
            <select className='form-control' placeholder="From Time">
              {timeSlots.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <div className="col-1">
            <span style={{fontSize:'30px'}}>-</span>
          </div>
          <div className="col-5">
            <select className='form-control' placeholder="To Time">
              {timeSlots.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EventModal;
