import React, { useState, useEffect } from 'react';
import { post } from '../../services/ApiService';
import { Select, Button } from 'antd'; // Import Ant Design components
const { Option } = Select;

const AddEventForm = ({ selectedDate, onClose, onEventAdded, users, existingEvent }) => {
    const [loading, setIsLoading] = useState(false);
    const [delLoading, setIsDelLoading] = useState(false);
    const [eventName, setEventName] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [repeat, setRepeat] = useState('Never');
    const [isOff, setIsOff] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    
    const timeSlots = Array.from({ length: 24 * 2 }, (_, index) => {
        const hours = Math.floor(index / 2);
        const minutes = index % 2 === 0 ? '00' : '30';
        return `${hours.toString().padStart(2, '0')}:${minutes}`;
    });

    useEffect(() => {
        if (existingEvent) {
            setEventName(existingEvent.eventName || '');
            setStartTime(existingEvent.startTime || '');
            setEndTime(existingEvent.endTime || '');
            setRepeat(existingEvent.repeat || 'Never');
            setIsOff(existingEvent.isOff || false);
            setSelectedUsers(existingEvent.userIds || []);
            setStartDate(existingEvent.startDate 
                ? new Date(existingEvent.startDate).toISOString().split('T')[0] 
                : ''
            );
        } else {
            setEventName('');
            setStartTime('');
            setEndTime('');
            setRepeat('Never');
            setIsOff(false);
            setSelectedUsers([]);
            setStartDate('');
        }
    }, [existingEvent]);

    const handleEventNameChange = (e) => {
        const value = e.target.value;
        setEventName(value);
        setIsOff(value === 'CLOSE'); // Set isOff to true if 'CLOSE' is selected, false otherwise
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            eventName,
            startDate,
            startTime,
            endTime,
            repeat,
            isOff,
            userIds: selectedUsers,
            eventId: existingEvent?._id,
        };

        setIsLoading(true);
        const res = existingEvent 
        ? await post('api/shifts/event/update', formData)
        : await post('api/shifts/event/add', formData);
        setIsLoading(false);
        if(res.success){
            setEventName('');
            setStartTime('');
            setEndTime('');
            setRepeat('Never');
            setIsOff(false);
            setSelectedUsers([]);
            setStartDate('');

            onEventAdded();
            onClose();
        }
    };

    const handleDeleteEvent = async () => {
        if (existingEvent && existingEvent._id) {
            try {
                const formData = {
                    eventId:existingEvent._id,
                };
                setIsDelLoading(true);
                const res = await post('api/shifts/event/delete', formData);
                setIsDelLoading(false);
                if(res.success){
                    onEventAdded();
                    onClose();
                }
            } catch (error) {
                setIsDelLoading(false);
                console.error("Error deleting event:", error);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className='col-12 mt-4'>
                <select className='form-control' value={eventName} onChange={handleEventNameChange} required>
                    <option value="">Select Event</option>
                    <option value="OPEN">OPEN</option>
                    <option value="CLOSE">CLOSE</option>
                </select>
            </div>
            <div className='col-12 mt-4'>
                <input className='form-control' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} placeholder="Start date" required readOnly={existingEvent ? true : false} />
            </div>

            <div className="row mt-4">
                <div className="col-5">
                    <select className='form-control' placeholder="From Time" value={startTime} onChange={(e) => setStartTime(e.target.value)}>
                        {timeSlots.map((time) => (
                            <option key={time} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-1">
                    <span style={{fontSize:'30px'}}>-</span>
                </div>
                <div className="col-6">
                    <select className='form-control' placeholder="End Time" value={endTime} onChange={(e) => setEndTime(e.target.value)}>
                        {timeSlots.map((time) => (
                            <option key={time} value={time}>
                                {time}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <select className='form-control mt-4' value={repeat} onChange={(e) => setRepeat(e.target.value)}>
                <option value="Never">Never</option>
                <option value="Weekly">Weekly</option>
            </select>
            <div className='col-12 mt-4 mb-4'>
                <Select 
                    className='form-control' 
                    mode="multiple" 
                    placeholder="Select Users" 
                    value={selectedUsers} 
                    onChange={setSelectedUsers}
                >
                    {users.map(user => (
                        <Option key={user._id} value={user._id}>{user.firstname} {user.lastname}</Option>
                    ))}
                </Select>
            </div>
            <button className='btn btn-primary btn-sm m-2 mt-4' type="submit" disabled={loading}>
                {loading && <i className="fa fa-spinner fa-spin"></i>}  {existingEvent ? 'Update Event' : 'Add Event'}
            </button>
            {existingEvent && (
                <button className='btn btn-danger btn-sm m-2' onClick={handleDeleteEvent} disabled={delLoading}>
                  {delLoading && <i className='fa fa-spinner fa-spin'></i>}  Delete Event
                </button>
            )}
        </form>
    );
};

export default AddEventForm;
