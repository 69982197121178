import React, { useState, useEffect } from 'react';
import { get,post } from '../services/ApiService';
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../redux/Authentication";
import { DateTimeFormater } from '../services/Helper';
import './style.css';
import { notification } from 'antd'; 
import { selectUser } from '../redux/selector';
import { useSelector } from 'react-redux';

import NotificationDropdown from './NotificationDropdown';

const Header = () => {
  const user = useSelector(selectUser);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [gslNotifications, setGslNotifications] = useState([]);
  const [pendingPmedNotifications, setPendingPmedNotifications] = useState([]);
  const [completePmedNotifications, setCompletePmedNotifications] = useState([]);
  const [isMark, setIsMark] = useState(false);

  

  const handleLogout = async (e) => {
    const id = localStorage.getItem("token");
    const logouts = await localStorage.removeItem("token");
    dispatch(logout());
    navigate("/login");

  };
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const res = await get('api/notifications');
        if(res.success){
          setNotifications(res.notifications);
          setGslNotifications(res.gslNotifications)
          setPendingPmedNotifications(res.pendingPmedNotifications)
          setCompletePmedNotifications(res.completePmedNotifications)
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      } 
    };
    fetchNotifications(); 
  }, []);


  const deleteNotifications = async (type) => {
    try {
      setIsMark(true);
      const button = document.querySelector(`.mark-${type}`);
      if (button) button.textContent = 'Please wait...';
      const Body = {
        type
      }
      const res = await post('api/notifications/delete', Body);

      button.textContent = 'Mark all read';
      setIsMark(false);
      if(res.success){
        switch (type) {
          case 'AssessmentComplete':
            setNotifications(res.notifications);
            break;
          case 'GslMessage':
            setGslNotifications(res.notifications);
            break;
          case 'PendingPmedMessage':
            setPendingPmedNotifications(res.notifications);
            break;
          case 'CompletePmedMessage':
            setCompletePmedNotifications(res.notifications);
            break;
          default:
            break;
        }
        notification.success({
          message: 'Success',
          description: 'Notifications marked as read successfully.',
        });
      }else{
        notification.error({
          message: 'Error',
          description: 'Failed to mark notifications as read.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to mark notifications as read.',
      });
      console.error('Error fetching notifications:', error);
      setIsMark(false);

    } 
  };
  const deleteSingleNotifications = async (id, type) => {
    try {
      // First, make the API request
      const res = await get(`api/notifications/${id}/delete`);
  
      if (res.success) {
        switch (type) {
          case 'AssessmentComplete':
            setNotifications(res.notifications);
            break;
          case 'GslMessage':
            setGslNotifications(res.notifications);
            break;
          case 'PendingPmedMessage':
            setPendingPmedNotifications(res.notifications);
            break;
          case 'CompletePmedMessage':
            setCompletePmedNotifications(res.notifications);
            break;
          default:
            break;
        }
        
        notification.success({
          message: 'Success',
          description: 'Notification marked as read successfully.',
        });
      } else {
        notification.error({
          message: 'Error',
          description: 'Failed to mark notification as read.',
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to mark notification as read.',
      });
      console.error('Error fetching notifications:', error);
    }
  };
  

  return (
    <>
  <header className="main-header bg-body position-relative d-none d-xl-block px-10 py-6">
    <div className="container-fluid">
      <nav className="navbar navbar-light py-0 row no-gutters px-3 px-lg-0">
        <div className="col-md-4 px-0 px-md-6 order-1 order-md-0">

        </div>
        <div className="col-md-6 d-flex flex-wrap justify-content-md-end align-items-center order-0 order-md-1">
          <NotificationDropdown
            notifications={gslNotifications}
            type="GslMessage"
            isMark={isMark}
            icon="far fa-comment-dots"
            title="Gsl order notifications"
            deleteNotifications={deleteNotifications}
            deleteSingleNotifications={deleteSingleNotifications}
          />
          <NotificationDropdown
            notifications={pendingPmedNotifications}
            type="PendingPmedMessage"
            isMark={isMark}
            icon="far fa-comments"
            title="Pending P order notifications"
            deleteNotifications={deleteNotifications}
            deleteSingleNotifications={deleteSingleNotifications}
          />
          <NotificationDropdown
            notifications={completePmedNotifications}
            type="CompletePmedMessage"
            isMark={isMark}
            icon="far fa-envelope"
            title="Complete order notifications"
            deleteNotifications={deleteNotifications}
            deleteSingleNotifications={deleteSingleNotifications}
          />
          <NotificationDropdown
            notifications={notifications}
            type="AssessmentComplete"
            isMark={isMark}
            icon="far fa-bell"
            title="Assessment complete notifications"
            deleteNotifications={deleteNotifications}
            deleteSingleNotifications={deleteSingleNotifications}
          />

          <div className="dropdown pl-2 py-2">
            <a href="#" className="dropdown-toggle text-heading pr-3 pr-sm-6 d-flex align-items-center justify-content-end" data-bs-toggle="dropdown">
            <div
            className="initials-avatar"
            style={{
              width: 50,
              height: 50,
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#4e7661',
              color: '#fff',
              fontSize: '1.5rem',
            }}
          >
            {user?.firstname?.[0]}{user?.lastname?.[0]}
            
          </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end w-100">
              <a className="dropdown-item" href="/profile">My Profile</a>
              <a className="dropdown-item" onClick={handleLogout}>Logout</a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>




    </>
  )
}

export default Header