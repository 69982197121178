import React, { useState, useEffect, useRef } from "react";
import Layout from '../../../components/Layout'
import { get, post } from '../../../services/ApiService';
import "../../custom-style.css";
import Loader from "../../../components/Loader";
import Pagination from '../../../components/Pagination';
import toast, { Toaster } from 'react-hot-toast';
import Table from "./Table";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import usePermissionCheck from "../../../hooks/usePermissionCheck";

const List = () => {
    const canEdit = usePermissionCheck(['Near Miss Edit']);
    const [filters, setFilters] = useState({
        limit: '20',
        date:'',
        sortOrder: 'newest'
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setNotFound] = useState(false); 
    const [orderErrors, setOrderErrors] = useState([]); 
    const [logs, setLogs] = useState([]); 
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchData()
    }, [currentPage, filters]);
    
    const fetchData = async () => {

        try {
            setIsLoading(true);
            const Body = {
              page: currentPage,
              ...filters,
            };
            const res = await post(`api/external-log/near-miss/list`, Body);
            if (res.success) {
              setIsLoading(false);
              setLogs(res.logs);
              setTotalPages(res.totalPages);
              setNotFound(false);

            } else {
              setLogs([]);
              setTotalPages(1);
              setIsLoading(false);
              setNotFound(true);
            }
        } catch (error) {
            setLogs([]);
            setTotalPages(1);
            setIsLoading(false);
            setNotFound(true);
            console.error(error.message);
        }
    };


    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };

    const handleSortOrderChange = (e) => {
        setFilters({
            ...filters,
            sortOrder: e.target.value
        });
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const resetFilters = () => {
        setFilters({

            limit: '20',
            date: '', // Ensure dateRange is properly reset
            sortOrder: 'newest'
        });
        setCurrentPage(1); 
        setLogs([]); 
        setTotalPages(1); 
    };



  return (
    <>
        <Layout>
            <Toaster 
                position="top-center"
                reverseOrder={false}
            />
            <div className="row mb-9 align-items-center justify-content-between">
                <div className="col-sm-6 mb-8 mb-sm-0">
                    <h2 className="fs-4 mb-0">Near Miss Error Logs</h2>
                    <Breadcrumbs />
                </div>
                <div className="col-sm-6 col-md-3 d-flex flex-wrap justify-content-sm-end">
                    <Link to={`/near-miss-logs/add`} className="btn btn-primary btn-sm">Add New</Link>
                </div>
            </div>
            <div className="card mb-4 rounded-4 p-7">
                <div className="card-header bg-transparent px-0 pt-0 pb-7">
                <div className="row align-items-center justify-content-between">

                    <div className="col-lg-3 col-sm-6 col-3">
                        <input type="date" name="date" className="form-control" />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="sortOrder" value={filters.sortOrder} onChange={handleSortOrderChange}>
                            <option value="newest">Newest to Oldest</option>
                            <option value="oldest">Oldest to Newest</option>
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <select className="form-select" name="limit" value={filters.limit} onChange={handleFilterChange}>
                            <option value="10">Show 10</option>
                            <option value="20">Show 20</option>
                            <option value="50">Show 50</option>
                            <option value="100">Show 100</option>
                        </select>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-3">
                        <Link className="mt-4 text-danger" onClick={resetFilters}><i className="fa fa-sync" style={{fontSize:'20px',marginTop:'12px'}}></i></Link>
                    </div>

                </div>
                </div>
                <div className="card-body px-0 pt-7 pb-0">
                    {isLoading  ? (
                        <div className="text-center">
                            <Loader />
                        </div>
                    ):(
                        logs.length > 0 && <Table logs={logs} canEdit={canEdit} />
                    )}
                    {!isLoading && isNotFound && 
                        <div className="text-center">
                        <img src="/assets/images/no-data.png" style={{width:'60px',opacity:'0.5'}} alt="" />
                        <h6 style={{color:'#918f8f',fontFamily: 'math',fontWeight: '500'}}>No Data Found</h6>
                        </div>
                    }
                </div>
            </div>
            {totalPages && totalPages > 1 && 
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
            }
        </Layout>
    </>
  )
}

export default List