import React, { useState, useEffect } from "react";
import Layout from '../../components/Layout';
import "../custom-style.css";
import 'react-quill/dist/quill.snow.css';
import Breadcrumbs from "../../components/Breadcrumbs";
import { Link } from "react-router-dom";
import { Modal, Form, Input, Button } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import { post, get } from "../../services/ApiService"; // Ensure you have `del` for delete requests

const Notes = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState([]);
  const [editingNote, setEditingNote] = useState(null); // Track the note being edited
  const [form] = Form.useForm();

  // Function to handle showing the modal
  const handleNewNoteClick = () => {
    setEditingNote(null); // Clear any previous editing note
    form.resetFields();
    setShowModal(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setShowModal(false);
    form.resetFields();
  };

  // Fetch notes on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await get('api/notes/list');
      if (res.success) {
        setNotes(res.notes);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);

      const formData = {
        ...values,
        category: 'General',
      };

      let res;
      if (editingNote) {
        // If editing, send a PUT request to update the note
        res = await post(`api/notes/update/${editingNote._id}`, formData);
      } else {
        // If creating, send a POST request
        res = await post('api/notes/add', formData);
      }

      if (res.success) {
        toast.success(res.message);
        fetchData(); // Refresh the notes list
        handleCloseModal(); // Close modal after success
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle editing a note
  const handleEditNote = (note) => {
    setEditingNote(note); // Set the note being edited
    form.setFieldsValue({
      title: note.title,
      description: note.description,
    });
    setShowModal(true); // Show modal for editing
  };

  // Function to handle deleting a note with confirmation
  const handleDeleteNote = (noteId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this note?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        try {
          const res = await get(`api/notes/delete/${noteId}`);
          if (res.success) {
            toast.success("Note deleted successfully.");
            fetchData(); // Refresh the notes list
          } else {
            toast.error("Failed to delete the note.");
          }
        } catch (error) {
          toast.error('An error occurred. Please try again.');
        }
      },
    });
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Layout>
        <div>
          <div className="row mb-9 align-items-center">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-6 mb-8 mb-sm-0">
                  <h2 className="fs-4 mb-0">Notes</h2>
                  <Breadcrumbs />
                </div>
              </div>
              <div className="row" style={{ marginTop: '60px' }}>
                <div className="col-lg-3">
                  <div
                    className="bt-sec text-center note-btn-add"
                    style={{ height: '170px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                  >
                    <Link className="btn-add" onClick={handleNewNoteClick}>
                      <p className="m-0 p-0"><i className="fa fa-plus"></i></p>
                      <p className="m-0 p-0">New Note</p>
                    </Link>
                  </div>
                </div>
                {notes && notes.length > 0 && notes.map((note) => (
                  <div className="col-lg-3" key={note._id}>
                    <div className="card note-card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-12">
                            <h6 className="m-0 p-0 note-title">{note.title}</h6>
                          </div>
                        </div>
                        <hr className="p-0" />
                        <div className="note-body">
                          {note.description}
                        </div>
                        <hr className="p-0" />
                        <div className="note-footer text-end">
                          <div className="row">
                          <div className="col-4">
                            <div className="d-flex">
                              <Link
                                className="note-edit-btn"
                                onClick={() => handleEditNote(note)} // Trigger edit on click
                              >
                                <i className="fa fa-pen note-edit-icon"></i>
                              </Link>
                              <Link
                                className="note-delete-btn"
                                onClick={() => handleDeleteNote(note._id)} // Trigger delete on click
                                style={{ marginLeft: '10px' }}
                              >
                                <i className="fa fa-trash note-delete-icon" style={{ color: 'red' }}></i>
                              </Link>
                            </div>
                          </div>
                          <div className="col-8">
                          {new Date(note.createdAt).toLocaleDateString('en-GB')}

                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Ant Design Modal */}
          <Modal
            title={editingNote ? "Edit Note" : "Add New Note"}
            open={showModal}
            onCancel={handleCloseModal}
            footer={null}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Form.Item
                label="Note Title"
                name="title"
                rules={[{ required: true, message: 'Please enter the note title' }]}
              >
                <Input placeholder="Enter note title" />
              </Form.Item>
              <Form.Item
                label="Note Description"
                name="description"
                rules={[{ required: true, message: 'Please enter the note description' }]}
              >
                <Input.TextArea placeholder="Enter note description" rows={6} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" loading={isLoading}>
                  {editingNote ? "Update Note" : "Save Note"}
                </Button>
                <Button onClick={handleCloseModal} style={{ marginLeft: '8px' }}>
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Layout>
    </>
  );
};

export default Notes;
